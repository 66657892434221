import React, { useState, useEffect, useRef, useMemo } from 'react';
import { createChart } from 'lightweight-charts';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { formattedNum } from '../../utils';
import styled from 'styled-components';
import { usePrevious } from 'react-use';
import './style.css';
import { isMobile } from 'react-device-detect';
dayjs.extend(utc);

export const CHART_TYPES = {
  BAR: 'BAR',
  AREA: 'AREA',
};

const Wrapper = styled.div`
  position: relative;
  max-height: 400px;
`;

// constant height for charts
const HEIGHT = isMobile ? 300 : window.innerHeight * 0.6;

const TradingViewChart = ({
  type = CHART_TYPES.BAR,
  data,
  base,
  baseChange,
  field,
  title,
  width,
  useWeekly = false,
  expanded,
}: any) => {
  const ref = useRef();
  const dataPrev = usePrevious(data);
  // pointer to the chart object
  const [chartCreated, setChartCreated] = useState<any>(false);

  // parese the data and format for tardingview consumption
  const formattedData = useMemo(
    () =>
      data?.map((entry: any) => {
        return {
          time: dayjs.unix(entry.date).utc().format('YYYY-MM-DD'),
          value: parseFloat(entry[field]),
        };
      }),
    [data, field]
  );

  // adjust the scale based on the type of chart
  const topScale = type === CHART_TYPES.AREA ? 0.32 : 0.2;

  const textColor = 'black';

  // reset the chart if them switches
  useEffect(() => {
    //eslint-disable-next-line
    // @ts-ignore
    if (chartCreated && ref.current?.children.length < 2 && data !== dataPrev) {
      // remove the tooltip element
      const tooltip = document.getElementById('tooltip-id' + type);
      tooltip!.innerHTML = '';
      chartCreated.resize(0, 0);
      setChartCreated(false);
    }
  }, [chartCreated, data, dataPrev, type, base, baseChange]);

  // if no chart created yet, create one with options and add to DOM manually
  useEffect(() => {
    //eslint-disable-next-line
    // @ts-ignore
    if (!chartCreated && ref.current?.children.length < 2 && data !== dataPrev && formattedData) {
      //eslint-disable-next-line
      // @ts-ignore
      const chart = createChart(ref.current, {
        width: width,
        height: HEIGHT + 50,
        layout: {
          backgroundColor: 'transparent',
          textColor: textColor,
        },
        rightPriceScale: {
          scaleMargins: {
            top: topScale,
            bottom: 0,
          },
          borderVisible: false,
        },
        timeScale: {
          borderVisible: false,
        },
        grid: {
          horzLines: {
            color: 'rgba(197, 203, 206, 0.5)',
            visible: false,
          },
          vertLines: {
            color: 'rgba(197, 203, 206, 0.5)',
            visible: false,
          },
        },
        crosshair: {
          horzLine: {
            visible: false,
            labelVisible: false,
            width: 2,
          },
          vertLine: {
            visible: true,
            style: 0,
            width: 2,
            color: '#000000',
            labelVisible: false,
          },
        },
        localization: {
          priceFormatter: (val: any) => formattedNum(val, true),
        },
      });

      const series =
        type === CHART_TYPES.BAR
          ? chart.addHistogramSeries({
              color: '#0033E7',
              priceFormat: {
                type: 'volume',
              },
              scaleMargins: {
                top: 0.32,
                bottom: 0,
              },
              //eslint-disable-next-line
              // @ts-ignore
              lineColor: '#0033E7',
              lineWidth: 2,
            })
          : chart.addAreaSeries({
              topColor: '#0033E7',
              bottomColor: 'rgba(0, 51, 231, 0)',
              lineColor: '#0033E7',
              lineWidth: 3,
            });

      series.setData(formattedData);
      const toolTip = document.getElementById('tooltip-id' + type);
      if (toolTip) {
        toolTip.setAttribute('id', 'tooltip-id' + type);
        toolTip.onclick = () => console.log('clicked');
        toolTip.className = 'three-line-legend-dark';

        toolTip.style.fontWeight = '500';
        toolTip.style.left = -4 + 'px';
        toolTip.style.backgroundColor = 'transparent';
      }
      // format numbers
      const percentChange = baseChange?.toFixed(2);
      const formattedPercentChange = (+percentChange > 0 ? '+' : '') + percentChange + '%';
      const color = +percentChange >= 0 ? 'green' : 'red';

      // get the title of the chart
      const setLastBarText = () => {
        if (toolTip) {
          toolTip.innerHTML =
            `<div style="font-size: 16px; margin: 4px 0px; color: ${textColor};">${title} ${
              type === CHART_TYPES.BAR && !useWeekly ? '(24hr)' : ''
            }</div>` +
            `<div style="font-size: ${
              isMobile ? '16px' : '22px'
            }; display: flex; align-items: center; margin: 4px 0px; color:${textColor}" >` +
            formattedNum(base ?? 0, true) +
            `<span style="margin-left: 10px; font-size: 16px; color: ${color};">${formattedPercentChange}</span>` +
            `<span style="margin-left: 10px; font-size: 16px;" id="expand"> </span>` +
            '</div>';
        }
      };
      setLastBarText();

      // update the title when hovering on the chart
      chart.subscribeCrosshairMove(function (param: any) {
        if (
          param === undefined ||
          param.time === undefined ||
          param.point.x < 0 ||
          param.point.x > width ||
          param.point.y < 0 ||
          param.point.y > HEIGHT
        ) {
          setLastBarText();
        } else {
          const dateStr = useWeekly
            ? dayjs(param.time.year + '-' + param.time.month + '-' + param.time.day)
                .startOf('week')
                .format('MMMM D, YYYY') +
              '-' +
              dayjs(param.time.year + '-' + param.time.month + '-' + param.time.day)
                .endOf('week')
                .format('MMMM D, YYYY')
            : dayjs(param.time.year + '-' + param.time.month + '-' + param.time.day).format('MMMM D, YYYY');
          const price = param.seriesPrices.get(series);

          if (toolTip) {
            toolTip.innerHTML =
              `<div style="font-size: 16px; margin: 4px 0px; color: ${textColor};">${title}</div>` +
              '<div style="display: flex; align-items: center;">' +
              `<div style="font-size: 22px; margin: 4px 10px 4px 0; color: ${textColor}">` +
              formattedNum(price, true) +
              '</div>' +
              '<div>' +
              dateStr +
              `<span style="margin-left: 10px; font-size: 16px;"> </span>` +
              '</div>' +
              '</div>';
          }
        }
      });

      chart.timeScale().fitContent();

      setChartCreated(chart);
    }
    // eslint-disable-next-line
  }, [base, baseChange, dataPrev, data, formattedData, textColor, title, topScale, type, useWeekly, width]);

  useEffect(() => {
    const expandBtns = document.getElementById('expand');
    if (!expandBtns) return;
    if (expanded) {
      expandBtns.innerHTML = ' ';
    } else {
      expandBtns.innerHTML = '▴';
    }
  }, [expanded]);

  // responsiveness
  useEffect(() => {
    if (width) {
      chartCreated && chartCreated.resize(width, HEIGHT - 40);
      chartCreated && chartCreated.timeScale().scrollToPosition(0);
    }
  }, [chartCreated, width]);

  return (
    <Wrapper>
      {/* //eslint-disable-next-line
// @ts-ignore */}
      <div ref={ref} id={'test-id' + type}>
        <div id={'tooltip-id' + type} />
      </div>
    </Wrapper>
  );
};

export default React.memo(TradingViewChart);
