const STEPEX_LIST = 'stepex';

// lower index == higher priority for token import
export const DEFAULT_LIST_OF_LISTS: string[] = [STEPEX_LIST];

// default lists to be 'active' aka searched across
export const DEFAULT_ACTIVE_LIST_URLS: string[] = [STEPEX_LIST];

export const STEPEX_TOKENS = {
  current: {
    keywords: ['stepex', 'tokens', 'fitfi'],
    logoURI: '',
    name: 'Stepex Token List',
    timestamp: '2022-05-25T20:37:00.000+00:00',
    tokens: [
      {
        address: '0xE3F5a90F9cb311505cd691a46596599aA1A0AD7D',
        chainId: 1234,
        decimals: 6,
        name: 'USDC',
        coingeckoId: 'usd-coin',
        logoUri: 'https://assets.coingecko.com/coins/images/6319/small/USD_Coin_icon.png?1547042389',
        symbol: 'USDC',
      },
      {
        address: '0x4792C1EcB969B036eb51330c63bD27899A13D84e',
        chainId: 1234,
        decimals: 18,
        name: 'FAME',
        coingeckoId: 'fame-mma',
        logoUri: 'https://assets.coingecko.com/coins/images/25267/small/FJTwuXxE_400x400.jpg?1651066664',
        symbol: 'FAME',
      },
      {
        address: '0x3028b4395F98777123C7da327010c40f3c7Cc4Ef',
        chainId: 1234,
        decimals: 18,
        name: 'NUM',
        coingeckoId: 'numbers-protocol',
        logoUri: 'https://assets.coingecko.com/coins/images/20495/small/5J3RAUO2_400x400.jpg?1637131666',
        symbol: 'NUM',
      },
      {
        address: '0x68b2DFC494362AAE300F2C401019205d8960226b',
        chainId: 1234,
        decimals: 18,
        name: 'KCAL',
        coingeckoId: '',
        logoUri: require('../assets/images/kcal.png'),
        symbol: 'KCAL',
      },
      {
        address: '0x264c1383EA520f73dd837F915ef3a732e204a493',
        chainId: 1234,
        decimals: 6,
        name: 'REJUVE',
        coingeckoId: '',
        logoUri: 'https://rejuve.ai/wp-content/uploads/2023/03/Rjv-Icon.png',
        symbol: 'RJV',
      },
      {
        address: '0x0bbe45a033Aab7A90006C05aa648efD3d2993F9E',
        chainId: 1234,
        decimals: 18,
        name: 'PRIMAL',
        coingeckoId: '',
        logoUri: require('../assets/images/primal.png'),
        symbol: 'PRIMAL',
      },
      {
        address: '0xfA9343C3897324496A05fC75abeD6bAC29f8A40f',
        logoUri: require('../assets/images/Tether-logo.webp'),
        chainId: 1234,
        decimals: 6,
        name: 'USDT',
        coingeckoId: 'tether',
        symbol: 'USDT',
      },
      {
        address: '0x818ec0A7Fe18Ff94269904fCED6AE3DaE6d6dC0b',
        logoUri: 'https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880',
        chainId: 1234,
        decimals: 18,
        name: 'ETH',
        coingeckoId: 'ethereum',
        symbol: 'ETH',
      },
      {
        address: '0xB44a9B6905aF7c801311e8F4E76932ee959c663C',
        logoUri: 'https://assets.coingecko.com/coins/images/7598/small/wrapped_bitcoin_wbtc.png?1548822744',
        chainId: 1234,
        decimals: 8,
        name: 'WBTC',
        coingeckoId: 'wrapped-bitcoin',
        symbol: 'WBTC',
      },
      {
        address: '0xb58A9d5920AF6aC1a9522B0B10F55dF16686D1b6',
        logoUri:
          'https://assets.coingecko.com/coins/images/25015/small/801485424e1f49bc8d0facff9287eb9b_photo.png?1649827972',
        chainId: 1234,
        decimals: 18,
        name: 'WFITFI',
        coingeckoId: 'step-app-fitfi',
        symbol: 'WFITFI',
      },
      {
        address: '0xeFAeeE334F0Fd1712f9a8cc375f427D9Cdd40d73',
        logoUri: 'https://assets.coingecko.com/coins/images/825/small/bnb-icon2_2x.png?16449798500',
        chainId: 1234,
        decimals: 18,
        name: 'BNB',
        coingeckoId: 'binancecoin',
        symbol: 'BNB',
      },
      {
        address: '0xd6070ae98b8069de6B494332d1A1a81B6179D960',
        logoUri: 'https://assets.coingecko.com/coins/images/12559/small/coin-round-red.png?1604021818',
        chainId: 1234,
        decimals: 18,
        name: 'AVAX',
        coingeckoId: 'avalanche-2',
        symbol: 'AVAX',
      },
      {
        address: '0xe6801928061CDbE32AC5AD0634427E140EFd05F9',
        logoUri: 'https://assets.coingecko.com/coins/images/15857/small/xcad.PNG?1622164789',
        chainId: 1234,
        decimals: 18,
        name: 'XCAD',
        coingeckoId: 'xcad-network',
        symbol: 'XCAD',
      },
      {
        address: '0xD67de0e0a0Fd7b15dC8348Bb9BE742F3c5850454',
        logoUri: 'https://assets.coingecko.com/coins/images/13915/small/4.png?1612838831',
        chainId: 1234,
        decimals: 18,
        name: 'DAO',
        coingeckoId: 'dao-maker',
        symbol: 'DAO',
      },
      {
        address: '0xf390830DF829cf22c53c8840554B98eafC5dCBc2',
        logoUri: 'https://assets.coingecko.com/coins/images/24379/small/XANA_Logo_neon_pink.png?1658197388',
        chainId: 1234,
        decimals: 18,
        name: 'XETA',
        coingeckoId: 'xana',
        symbol: 'XETA',
      },
      {
        address: '0x7db4072D6e26BBF35129E826d656f230F791CD2f',
        logoUri: require('../assets/svg/spex.svg'),
        chainId: 1234,
        decimals: 18,
        name: 'SPEX',
        coingeckoId: '',
        symbol: 'SPEX',
      },
      // {
      //   address: '0x34a8FBDB6429eB805b254b1989f4345Dd067F8fB',
      //   chainId: 12345,
      //   decimals: 6,
      //   name: 'USDC',
      //   symbol: 'USDC',
      // },
      // {
      //   address: '0x52Fda5373F365aF65eE85eb530159A41F1EEc05d',
      //   chainId: 12345,
      //   decimals: 18,
      //   name: 'KCAL',
      //   symbol: 'KCAL',
      // },
      // {
      //   address: '0x250948f8d1AE3E99b563C6E0fD27c4752Df6e02e',
      //   chainId: 12345,
      //   decimals: 18,
      //   name: 'WFITFI',
      //   symbol: 'WFITFI',
      // },
      // {
      //   address: '0x3086b251156FB7631cfD678617aFd3C8C6ccc747',
      //   chainId: 12345,
      //   decimals: 18,
      //   name: 'TEST1',
      //   symbol: 'TEST1',
      // },
      // {
      //   address: '0xa06130a5619f3b613262CaBfdDF739829e12B875',
      //   chainId: 12345,
      //   decimals: 18,
      //   name: 'TEST2',
      //   symbol: 'TEST2',
      // },
    ],
    version: { major: 1, minor: 0, patch: 0 },
  },
  pendingUpdate: null,
  loadingRequestId: 'yPCAUqXe-3DfLxc7neroO',
  error: null,
};

export const TOKENS_PAIRS = [
  {
    liquidityToken: {
      decimals: 18,
      symbol: 'SWAP-LP',
      name: 'Swap-LP-Token',
      chainId: 1234,
      address: '0x4F4D77f1f8B12B183C78C53d6Dd9beaC805e1B09',
    },
    tokens: [
      {
        address: '0xE3F5a90F9cb311505cd691a46596599aA1A0AD7D',
        chainId: 1234,
        decimals: 6,
        name: 'USDC',
        coingeckoId: 'usd-coin',
        logoUri: 'https://assets.coingecko.com/coins/images/6319/small/USD_Coin_icon.png?1547042389',
        symbol: 'USDC',
      },
      {
        address: '0xfA9343C3897324496A05fC75abeD6bAC29f8A40f',
        logoUri: 'https://assets.coingecko.com/coins/images/325/small/Tether-logo.png?1598003707',
        chainId: 1234,
        decimals: 6,
        name: 'USDT',
        coingeckoId: 'tether',
        symbol: 'USDT',
      },
    ],
  },
  {
    liquidityToken: {
      decimals: 18,
      symbol: 'SWAP-LP',
      name: 'Swap-LP-Token',
      chainId: 1234,
      address: '0xEDf8A513C0e1fC89491E03946f908432D589327b',
    },
    tokens: [
      {
        address: '0x818ec0A7Fe18Ff94269904fCED6AE3DaE6d6dC0b',
        logoUri: 'https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880',
        chainId: 1234,
        decimals: 18,
        name: 'ETH',
        coingeckoId: 'ethereum',
        symbol: 'ETH',
      },
      {
        address: '0xE3F5a90F9cb311505cd691a46596599aA1A0AD7D',
        chainId: 1234,
        decimals: 6,
        name: 'USDC',
        coingeckoId: 'usd-coin',
        logoUri: 'https://assets.coingecko.com/coins/images/6319/small/USD_Coin_icon.png?1547042389',
        symbol: 'USDC',
      },
    ],
  },
  {
    liquidityToken: {
      decimals: 18,
      symbol: 'SWAP-LP',
      name: 'Swap-LP-Token',
      chainId: 1234,
      address: '0x9117372745dF764d2F1feB6372b358dE0F856b46',
    },
    tokens: [
      {
        address: '0xB44a9B6905aF7c801311e8F4E76932ee959c663C',
        logoUri: 'https://assets.coingecko.com/coins/images/7598/small/wrapped_bitcoin_wbtc.png?1548822744',
        chainId: 1234,
        decimals: 8,
        name: 'WBTC',
        coingeckoId: 'wrapped-bitcoin',
        symbol: 'WBTC',
      },
      {
        address: '0xE3F5a90F9cb311505cd691a46596599aA1A0AD7D',
        chainId: 1234,
        decimals: 6,
        name: 'USDC',
        coingeckoId: 'usd-coin',
        logoUri: 'https://assets.coingecko.com/coins/images/6319/small/USD_Coin_icon.png?1547042389',
        symbol: 'USDC',
      },
    ],
  },
  {
    liquidityToken: {
      decimals: 18,
      symbol: 'SWAP-LP',
      name: 'Swap-LP-Token',
      chainId: 1234,
      address: '0x6e835eBF3a4c98f62b06f36823f6432A77a4291a',
    },
    tokens: [
      {
        address: '0xB44a9B6905aF7c801311e8F4E76932ee959c663C',
        logoUri: 'https://assets.coingecko.com/coins/images/7598/small/wrapped_bitcoin_wbtc.png?1548822744',
        chainId: 1234,
        decimals: 8,
        name: 'WBTC',
        coingeckoId: 'wrapped-bitcoin',
        symbol: 'WBTC',
      },
      {
        address: '0x818ec0A7Fe18Ff94269904fCED6AE3DaE6d6dC0b',
        logoUri: 'https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880',
        chainId: 1234,
        decimals: 18,
        name: 'ETH',
        coingeckoId: 'ethereum',
        symbol: 'ETH',
      },
    ],
  },
  {
    liquidityToken: {
      decimals: 18,
      symbol: 'SWAP-LP',
      name: 'Swap-LP-Token',
      chainId: 1234,
      address: '0xc07A896d2e57D60A66f04D4Ff893216E6432E18b',
    },
    tokens: [
      {
        address: '0xeFAeeE334F0Fd1712f9a8cc375f427D9Cdd40d73',
        logoUri: 'https://assets.coingecko.com/coins/images/825/small/bnb-icon2_2x.png?1644979850',
        chainId: 1234,
        decimals: 18,
        name: 'BNB',
        coingeckoId: 'binancecoin',
        symbol: 'BNB',
      },
      {
        address: '0xE3F5a90F9cb311505cd691a46596599aA1A0AD7D',
        chainId: 1234,
        decimals: 6,
        name: 'USDC',
        coingeckoId: 'usd-coin',
        logoUri: 'https://assets.coingecko.com/coins/images/6319/small/USD_Coin_icon.png?1547042389',
        symbol: 'USDC',
      },
    ],
  },
  {
    liquidityToken: {
      decimals: 18,
      symbol: 'SWAP-LP',
      name: 'Swap-LP-Token',
      chainId: 1234,
      address: '0x669B2884e1e0941f97A44eCc29a8e2a39863a65E',
    },
    tokens: [
      {
        address: '0xeFAeeE334F0Fd1712f9a8cc375f427D9Cdd40d73',
        logoUri: 'https://assets.coingecko.com/coins/images/825/small/bnb-icon2_2x.png?1644979850',
        chainId: 1234,
        decimals: 18,
        name: 'BNB',
        coingeckoId: 'binancecoin',
        symbol: 'BNB',
      },
      {
        address: '0xfA9343C3897324496A05fC75abeD6bAC29f8A40f',
        logoUri: 'https://assets.coingecko.com/coins/images/325/small/Tether-logo.png?1598003707',
        chainId: 1234,
        decimals: 6,
        name: 'USDT',
        coingeckoId: 'tether',
        symbol: 'USDT',
      },
    ],
  },
  {
    liquidityToken: {
      decimals: 18,
      symbol: 'SWAP-LP',
      name: 'Swap-LP-Token',
      chainId: 1234,
      address: '0x8bA60723D569506A583F9c4C4E2525f01c1593EC',
    },
    tokens: [
      {
        address: '0xd6070ae98b8069de6B494332d1A1a81B6179D960',
        logoUri: 'https://assets.coingecko.com/coins/images/12559/small/coin-round-red.png?1604021818',
        chainId: 1234,
        decimals: 18,
        name: 'AVAX',
        coingeckoId: 'avalanche-2',
        symbol: 'AVAX',
      },
      {
        address: '0xE3F5a90F9cb311505cd691a46596599aA1A0AD7D',
        chainId: 1234,
        decimals: 6,
        name: 'USDC',
        coingeckoId: 'usd-coin',
        logoUri: 'https://assets.coingecko.com/coins/images/6319/small/USD_Coin_icon.png?1547042389',
        symbol: 'USDC',
      },
    ],
  },
  {
    liquidityToken: {
      decimals: 18,
      symbol: 'SWAP-LP',
      name: 'Swap-LP-Token',
      chainId: 1234,
      address: '0xc8f6C6A51050e22fDc1b44D550a52464C6675B12',
    },
    tokens: [
      {
        address: '0xb58A9d5920AF6aC1a9522B0B10F55dF16686D1b6',
        logoUri: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19778.png',
        chainId: 1234,
        decimals: 18,
        name: 'WFITFI',
        coingeckoId: 'step-app-fitfi',
        symbol: 'WFITFI',
      },
      {
        address: '0xfA9343C3897324496A05fC75abeD6bAC29f8A40f',
        logoUri: 'https://assets.coingecko.com/coins/images/325/small/Tether-logo.png?1598003707',
        chainId: 1234,
        decimals: 6,
        name: 'USDT',
        coingeckoId: 'tether',
        symbol: 'USDT',
      },
    ],
  },
  {
    liquidityToken: {
      decimals: 18,
      symbol: 'SWAP-LP',
      name: 'Swap-LP-Token',
      chainId: 1234,
      address: '0xF2Fb82D81240E641A49076Fe6879c128a485bb3f',
    },
    tokens: [
      {
        address: '0xb58A9d5920AF6aC1a9522B0B10F55dF16686D1b6',
        logoUri: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19778.png',
        chainId: 1234,
        decimals: 18,
        name: 'WFITFI',
        coingeckoId: 'step-app-fitfi',
        symbol: 'WFITFI',
      },
      {
        address: '0xE3F5a90F9cb311505cd691a46596599aA1A0AD7D',
        chainId: 1234,
        decimals: 6,
        name: 'USDC',
        coingeckoId: 'usd-coin',
        logoUri: 'https://assets.coingecko.com/coins/images/6319/small/USD_Coin_icon.png?1547042389',
        symbol: 'USDC',
      },
    ],
  },
  {
    liquidityToken: {
      decimals: 18,
      symbol: 'SWAP-LP',
      name: 'Swap-LP-Token',
      chainId: 1234,
      address: '0xF8D99ECF5b7c12dfc905c6a8A43910dbdB440eDD',
    },
    tokens: [
      {
        address: '0xe6801928061CDbE32AC5AD0634427E140EFd05F9',
        logoUri: 'https://assets.coingecko.com/coins/images/15857/small/xcad.PNG?1622164789',
        chainId: 1234,
        decimals: 18,
        name: 'XCAD',
        coingeckoId: 'xcad-network',
        symbol: 'XCAD',
      },
      {
        address: '0xE3F5a90F9cb311505cd691a46596599aA1A0AD7D',
        chainId: 1234,
        decimals: 6,
        name: 'USDC',
        coingeckoId: 'usd-coin',
        logoUri: 'https://assets.coingecko.com/coins/images/6319/small/USD_Coin_icon.png?1547042389',
        symbol: 'USDC',
      },
    ],
  },
  {
    liquidityToken: {
      decimals: 18,
      symbol: 'SWAP-LP',
      name: 'Swap-LP-Token',
      chainId: 1234,
      address: '0xa6b7A11A7d85d85f757d8D0A34c302269dF2b073',
    },
    tokens: [
      {
        address: '0xD67de0e0a0Fd7b15dC8348Bb9BE742F3c5850454',
        logoUri: 'https://assets.coingecko.com/coins/images/13915/small/4.png?1612838831',
        chainId: 1234,
        decimals: 18,
        name: 'DAO',
        coingeckoId: 'dao-maker',
        symbol: 'DAO',
      },
      {
        address: '0xE3F5a90F9cb311505cd691a46596599aA1A0AD7D',
        chainId: 1234,
        decimals: 6,
        name: 'USDC',
        coingeckoId: 'usd-coin',
        logoUri: 'https://assets.coingecko.com/coins/images/6319/small/USD_Coin_icon.png?1547042389',
        symbol: 'USDC',
      },
    ],
  },
  {
    liquidityToken: {
      decimals: 18,
      symbol: 'SWAP-LP',
      name: 'Swap-LP-Token',
      chainId: 1234,
      address: '0x601e0D177297C81D764B901f21e5cF102dc8c62C',
    },
    tokens: [
      {
        address: '0xf390830DF829cf22c53c8840554B98eafC5dCBc2',
        logoUri: 'https://assets.coingecko.com/coins/images/24379/small/XANA_Logo_neon_pink.png?1658197388',
        chainId: 1234,
        decimals: 18,
        name: 'XETA',
        coingeckoId: 'xana',
        symbol: 'XETA',
      },
      {
        address: '0xE3F5a90F9cb311505cd691a46596599aA1A0AD7D',
        chainId: 1234,
        decimals: 6,
        name: 'USDC',
        coingeckoId: 'usd-coin',
        logoUri: 'https://assets.coingecko.com/coins/images/6319/small/USD_Coin_icon.png?1547042389',
        symbol: 'USDC',
      },
    ],
  },
];
