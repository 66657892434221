import { Currency, ETHER, Token } from 'stepex-sdk-mainnet';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import Logo from '../Logo';
import { WrappedTokenInfo } from 'state/lists/hooks';
import { STEPEX_TOKENS } from 'constants/lists';

const StyledEthereumLogo = styled.img<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
  border-radius: 24px;
`;

const StyledLogo = styled(Logo)<{ size: string; sizeraw: number }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  border-radius: ${({ size }) => size};
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
  background-color: ${({ theme }) => theme.white};
  transform: translateX(-${({ sizeraw }) => sizeraw}px);
`;

export default function CurrencyLogo({
  currency,
  size = '24px',
  style,
  sizeraw,
}: {
  currency?: Currency;
  size?: string;
  style?: React.CSSProperties;
  sizeraw?: number | undefined;
}) {
  const srcs: string[] = useMemo(() => {
    if (currency === ETHER) return [];
    const curr = STEPEX_TOKENS.current.tokens.filter((token) => token.symbol === currency?.symbol);

    if (currency instanceof Token) {
      if (currency instanceof WrappedTokenInfo) {
        return [
          //eslint-disable-next-line
          //@ts-ignore
          curr[0]?.logoUri.default ||
            curr[0]?.logoUri ||
            'https://assets.coingecko.com/coins/images/25015/small/801485424e1f49bc8d0facff9287eb9b_photo.png?1649827972',
        ];
      }

      return [
        //eslint-disable-next-line
        //@ts-ignore
        curr[0]?.logoUri.default ||
          curr[0]?.logoUri ||
          'https://assets.coingecko.com/coins/images/25015/small/801485424e1f49bc8d0facff9287eb9b_photo.png?1649827972',
      ];
    }
    return [];
  }, [currency]);

  if (currency === ETHER) {
    return (
      <StyledEthereumLogo
        src={
          'https://assets.coingecko.com/coins/images/25015/small/801485424e1f49bc8d0facff9287eb9b_photo.png?1649827972'
        }
        size={size}
        style={style}
      />
    );
  }

  return (
    <StyledLogo
      size={size}
      srcs={srcs}
      sizeraw={sizeraw || 0}
      alt={`${currency?.symbol ?? 'token'} logo`}
      style={style}
    />
  );
}
