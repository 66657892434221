import { createWeb3ReactRoot, Web3ReactProvider } from '@web3-react/core';
import 'inter-ui';
import TVLProvider from 'pages/context/TVLContext';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import { NetworkContextName } from './constants';
import './i18n';
import App from './pages/App';
import store from './state';
import ApplicationUpdater from './state/application/updater';
import ListsUpdater from './state/lists/updater';
import MulticallUpdater from './state/multicall/updater';
import TransactionUpdater from './state/transactions/updater';
import UserUpdater from './state/user/updater';
import ThemeProvider, { FixedGlobalStyle, ThemedGlobalStyle } from './theme';
import getLibrary from './utils/getLibrary';
import { ApolloProvider } from 'react-apollo';
import { client } from './graph/client';

const Web3ProviderNetwork = createWeb3ReactRoot(NetworkContextName);

if ('ethereum' in window) {
  window.ethereum.autoRefreshOnNetworkChange = false;
}

function Updaters() {
  return (
    <>
      <ListsUpdater />
      <UserUpdater />
      <ApplicationUpdater />
      <TransactionUpdater />
      <MulticallUpdater />
    </>
  );
}

ReactDOM.render(
  <>
    <FixedGlobalStyle />
    <Web3ReactProvider getLibrary={getLibrary}>
      <Web3ProviderNetwork getLibrary={getLibrary}>
        <ApolloProvider client={client}>
          <Provider store={store}>
            <Updaters />
            <ThemeProvider>
              <TVLProvider>
                <ThemedGlobalStyle />
                <HashRouter>
                  <App />
                </HashRouter>
              </TVLProvider>
            </ThemeProvider>
          </Provider>
        </ApolloProvider>
      </Web3ProviderNetwork>
    </Web3ReactProvider>
  </>,
  document.getElementById('root')
);
