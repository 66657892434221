import React, { useContext } from 'react';
import { isMobile } from 'react-device-detect';
import { createPortal } from 'react-dom';
import { Text } from 'rebass';
import { Price, Trade } from 'stepex-sdk-mainnet';
import styled, { ThemeContext } from 'styled-components';
import { computeTradePriceBreakdown } from 'utils/prices';
import { ONE_BIPS } from '../../constants';

export const TradeInfo = styled('div')`
  position: absolute;
  z-index: -1;
  right: 1.55rem;
  bottom: ${() => (isMobile ? '20%' : '5.25rem')};
  @media (min-width: 1450px) {
    zoom: 1.3;
  }
  @media (min-width: 1980px) {
    zoom: 2;
  }
  @media (max-height: 600px) {
    bottom: -5px;
  }
`;
export const PriceRow = styled('div')`
  display: flex;
  flex-direction: column;
  width: ${() => (isMobile ? '321px' : '305px')};
`;

export const Quote = styled('div')`
  margin-top: 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  span {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 400;
  }
`;

interface TradePriceProps {
  price?: Price;
  trade?: Trade;
  pool?: any;
  allowedSlippage?: number;
}

export default function TradePrice({ price, trade }: TradePriceProps) {
  //eslint-disable-next-line
  //@ts-ignore
  const theme = useContext(ThemeContext);
  const { priceImpactWithoutFee: priceImpact } = computeTradePriceBreakdown(trade);

  const show = Boolean(price?.baseCurrency && price?.quoteCurrency);

  const wrapper = document.getElementById('body-wrapper');

  return wrapper
    ? createPortal(
        <TradeInfo>
          <Text
            fontWeight={500}
            fontSize={14}
            color={theme.text2}
            style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}
          >
            {show ? (
              <PriceRow>
                <Quote>
                  <span>SHARE OF POOL</span>{' '}
                  <span>
                    {priceImpact ? (priceImpact.lessThan(ONE_BIPS) ? '<0.01%' : `${priceImpact.toFixed(2)}%`) : '-'}
                  </span>
                </Quote>
                <Quote>
                  <span>{`${price?.quoteCurrency?.symbol} per ${price?.baseCurrency?.symbol}`}</span>{' '}
                  <span>{price?.toSignificant(6)}</span>
                </Quote>
                <Quote>
                  <span>{`${price?.baseCurrency?.symbol} per ${price?.quoteCurrency?.symbol}`}</span>{' '}
                  <span>{price?.invert()?.toSignificant(6)}</span>
                </Quote>
              </PriceRow>
            ) : (
              '-'
            )}
          </Text>
        </TradeInfo>,
        wrapper
      )
    : null;
}
