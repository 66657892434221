import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core';
import { AbstractConnector } from '@web3-react/abstract-connector';

import Identicon from 'components/Identicon';
import { darken, lighten } from 'polished';
import React, { useMemo, useRef, useState } from 'react';
import { Activity } from 'react-feather';
import styled from 'styled-components';
import { shortenAddress } from 'utils';

import { NetworkContextName } from '../../constants';
import useENSName from '../../hooks/useENSName';
import { useWalletModalToggle } from '../../state/application/hooks';
import { isTransactionRecent, useAllTransactions } from '../../state/transactions/hooks';
import { TransactionDetails } from '../../state/transactions/reducer';
import { ButtonSecondary } from '../Button';
import CoinbaseWalletIcon from '../../assets/images/coinbaseWalletIcon.svg';
// import FortmaticIcon from '../../assets/images/fortmaticIcon.png';
// import PortisIcon from '../../assets/images/portisIcon.png';
import WalletConnectIcon from '../../assets/images/walletConnectIcon.svg';
import {
  // fortmatic,
  injected,
  //portis,
  walletconnect,
  walletlink,
} from '../../connectors';
import Loader from '../Loader';

import { RowBetween } from '../Row';
import WalletModal from '../WalletModal';
import AccountDetails from 'components/AccountDetails';
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import { isMobile } from 'react-device-detect';

const Web3StatusGeneric = styled(ButtonSecondary)`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  align-items: center;
  padding: 0.4rem;
  border-radius: 12px;
  cursor: pointer;
  user-select: none;
  :focus {
    outline: none;
  }
`;
const Web3StatusError = styled(Web3StatusGeneric)`
  background-color: ${({ theme }) => theme.red1};
  border: 1px solid ${({ theme }) => theme.red1};
  color: ${({ theme }) => theme.white};
  font-weight: 500;
  :hover,
  :focus {
    background-color: ${({ theme }) => darken(0.1, theme.red1)};
  }
`;

const Text = styled.p`
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0 0.3rem;
  width: fit-content;
  font-weight: 500;
  font-size: 12px;
`;

const NetworkIcon = styled(Activity)`
  margin-left: 0.25rem;
  margin-right: 0.5rem;
  width: 16px;
  height: 16px;
`;

const NetworkText = styled.p`
  color: black;
  font-size: 12px;
  opacity: 0.6;
  margin: 0 0.3rem;
  text-align: start;
  text-transform: none !important;
`;

const Web3StatusConnected = styled(Web3StatusGeneric)<{ pending?: boolean }>`
  background-color: ${({ theme }) => theme.primary1};
  border: 1px solid ${({ pending, theme }) => (pending ? theme.primary1 : theme.bg3)};
  color: ${({ pending, theme }) => (pending ? theme.white : theme.text1)};
  width: ${() => (isMobile ? '140px' : 'auto')};
  font-weight: 500;
  :hover,
  :focus {
    border: 1px solid ${({ pending, theme }) => (pending ? theme.primary1 : theme.bg3)};
    :focus {
      border: 1px solid ${({ pending, theme }) => (pending ? theme.primary1 : theme.bg3)};
    }
  }
`;
const IconWrapper = styled.div<{ size?: number }>`
  ${({ theme }) => theme.flexColumnNoWrap};
  align-items: center;
  justify-content: center;
  & > * {
    height: ${({ size }) => (size ? size + 'px' : '32px')};
    width: ${({ size }) => (size ? size + 'px' : '32px')};
  }
`;

const StatusWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (max-width: 420px) {
    align-items: flex-end;
  }
`;

const MenuFlyout = styled.span`
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  position: absolute;
  top: 3rem;
  right: 0rem;
  z-index: 100;
  width: 280px;
`;

const StyledLink = styled('a')`
  text-decoration: none;
  color: black;
  margin-right: 10px;
  font-size: ${() => (isMobile ? '12px' : '18px')};
  width: 'auto';
  &:hover {
    color: ${() => lighten(0.05, '#555555')};
  }
`;

// we want the latest one to come first, so return negative if a is after b
export function newTransactionsFirst(a: TransactionDetails, b: TransactionDetails) {
  return b.addedTime - a.addedTime;
}

function StatusIcon({ connector }: { connector: AbstractConnector }) {
  if (connector === injected) {
    return <Identicon />;
  } else if (connector === walletconnect) {
    return (
      <IconWrapper size={28}>
        <img src={WalletConnectIcon} alt={''} />
      </IconWrapper>
    );
  } else if (connector === walletlink) {
    return (
      <IconWrapper size={28}>
        <img src={CoinbaseWalletIcon} alt={''} />
      </IconWrapper>
    );
  }
  return null;
}

function Web3StatusInner() {
  const { error, account, connector } = useWeb3React();
  const [open, setOpen] = useState<boolean>(false);
  const { ENSName } = useENSName(account ?? undefined);
  const allTransactions = useAllTransactions();
  const toggleOpen = () => setOpen((prev) => !prev);

  const node = useRef<HTMLDivElement>();
  useOnClickOutside(node, open ? toggleOpen : undefined);

  const sortedRecentTransactions = useMemo(() => {
    const txs = Object.values(allTransactions);
    return txs.filter(isTransactionRecent).sort(newTransactionsFirst);
  }, [allTransactions]);

  const pending = sortedRecentTransactions.filter((tx) => !tx.receipt).map((tx) => tx.hash);

  const hasPendingTransactions = !!pending.length;
  const toggleWalletModal = useWalletModalToggle();
  if (account) {
    return (
      <StatusWrapper ref={node as any}>
        <StyledLink href="https://staking.step.exchange/" target="_blank">
          staking
        </StyledLink>
        <StyledLink href="https://lockdrop.step.exchange" target="_blank">
          lockdrop
        </StyledLink>
        <StyledLink href="https://info.step.exchange/" target="_blank">
          info
        </StyledLink>
        <Web3StatusConnected id="web3-status-connected" onClick={toggleOpen} pending={hasPendingTransactions}>
          {!hasPendingTransactions && connector && <StatusIcon connector={connector} />}
          {hasPendingTransactions ? (
            <RowBetween>
              <Text>{pending?.length} Pending</Text> <Loader stroke="white" />
            </RowBetween>
          ) : (
            <div>
              <Text>{ENSName || shortenAddress(account)}</Text>
              <NetworkText>Step Network</NetworkText>
            </div>
          )}
        </Web3StatusConnected>
        {open && (
          <MenuFlyout>
            <AccountDetails ENSName={ENSName} toggleOpen={toggleOpen} />
          </MenuFlyout>
        )}
      </StatusWrapper>
    );
  } else if (error) {
    return (
      <Web3StatusError onClick={toggleWalletModal}>
        <NetworkIcon />
        <Text>{error instanceof UnsupportedChainIdError ? 'Wrong Network' : 'Error'}</Text>
      </Web3StatusError>
    );
  } else {
    return (
      <>
        <StyledLink href="https://staking.step.exchange/" target="_blank">
          staking
        </StyledLink>
        <StyledLink href="https://lockdrop.step.exchange" target="_blank">
          lockdrop
        </StyledLink>
        <StyledLink href="https://info.step.exchange/" target="_blank">
          info
        </StyledLink>
      </>
    );
  }
}

export default function Web3Status() {
  const { active } = useWeb3React();
  const contextNetwork = useWeb3React(NetworkContextName);

  if (!contextNetwork.active && !active) {
    return null;
  }

  return (
    <>
      <Web3StatusInner />
      <WalletModal />
    </>
  );
}
