import styled from 'styled-components';

export const AcceptRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem 0.9rem 0.8rem 1.1rem;
  background-color: #ffffff;
  border-radius: 20px;
  span {
    font-size: 16px;
    color: #000000;
  }
`;
