import { JSBI, Pair, Percent, TokenAmount } from 'stepex-sdk-mainnet';
import { darken } from 'polished';
import React, { useState } from 'react';
import { ChevronRight } from 'react-feather';
import { Link } from 'react-router-dom';
import { Text } from 'rebass';
import styled from 'styled-components';
import { useTotalSupply } from '../../data/TotalSupply';

import { useActiveWeb3React } from '../../hooks';
import { useTokenBalance } from '../../state/wallet/hooks';
import { currencyId } from '../../utils/currencyId';
import { unwrappedToken } from '../../utils/wrappedCurrency';
import { ButtonPrimary, ButtonEmpty, BlackButton } from '../Button';

import Card, { GreyCard, LightCard } from '../Card';
import { AutoColumn } from '../Column';
import DoubleCurrencyLogo from '../DoubleLogo';
import { RowBetween, RowFixed, AutoRow } from '../Row';
import { Dots } from '../swap/styleds';
import { BIG_INT_ZERO, WFITFI } from '../../constants';
import { MouseoverTooltip } from 'components/Tooltip';
import { isMobile } from 'react-device-detect';
import { PoolPrice } from 'pages/RemoveLiquidity/price';
import { numWithCommas } from 'utils/prices';
import { useTokenData } from 'hooks/useTokenData';

export const FixedHeightRow = styled(RowBetween)`
  height: 24px;
  position: relative;
`;

const TokenPrice = styled.div`
  position: absolute;
  top: 5px;
  left: 110%;
  color: #888d9b;
`;

export const HoverCard = styled(Card)`
  border: 1px solid transparent;

  :hover {
    border: 1px solid ${({ theme }) => darken(0.06, theme.bg2)};
  }
`;
const StyledPositionCard = styled(LightCard)<{ bgColor: any; opened: boolean }>`
  position: relative;
  overflow: hidden;
  cursor: ${({ opened }) => (opened ? 'pointer' : 'default')};
  border: 1px solid ${({ theme }) => theme.white};
  background: ${({ theme }) => theme.white};
  padding: 7px;
  margin-bottom: 5px;

  :hover {
    background: ${({ theme, opened }) => (opened ? 'rgba(0, 0, 0, 0.03)' : theme.white)};
  }
`;

interface PositionCardProps {
  pair: Pair;
  setSelectedPair?: (value: Pair) => void;
  showUnwrapped?: boolean;
  border?: string;
  expanded?: boolean;
  stakedBalance?: TokenAmount; // optional balance to indicate that liquidity is deposited in mining pool
}

export function MinimalPositionCard({ pair, showUnwrapped = false, border }: PositionCardProps) {
  const { account } = useActiveWeb3React();

  const currency0 = showUnwrapped ? pair.token0 : unwrappedToken(pair.token0);
  const currency1 = showUnwrapped ? pair.token1 : unwrappedToken(pair.token1);
  const [showMore, setShowMore] = useState(false);

  const userPoolBalance = useTokenBalance(account ?? undefined, pair.liquidityToken);
  const totalPoolTokens = useTotalSupply(pair.liquidityToken);

  const poolTokenPercentage =
    !!userPoolBalance && !!totalPoolTokens && JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
      ? new Percent(userPoolBalance.raw, totalPoolTokens.raw)
      : undefined;

  const [token0Deposited, token1Deposited] =
    !!pair &&
    !!totalPoolTokens &&
    !!userPoolBalance &&
    // this condition is a short-circuit in the case where useTokenBalance updates sooner than useTotalSupply
    JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
      ? [
          pair.getLiquidityValue(pair.token0, totalPoolTokens, userPoolBalance, false),
          pair.getLiquidityValue(pair.token1, totalPoolTokens, userPoolBalance, false),
        ]
      : [undefined, undefined];

  const token0 = useTokenData(
    //eslint-disable-next-line
    //@ts-ignore
    currency0.symbol === 'FITFI' ? WFITFI.address.toLowerCase() : currency0?.address.toLowerCase()
  );
  const token1 = useTokenData(
    //eslint-disable-next-line
    //@ts-ignore
    currency1.symbol === 'FITFI' ? WFITFI.address.toLowerCase() : currency1?.address.toLowerCase()
  );

  return (
    <>
      {userPoolBalance && JSBI.greaterThan(userPoolBalance.raw, JSBI.BigInt(0)) && (
        <GreyCard border={border}>
          <AutoColumn gap="12px">
            <FixedHeightRow>
              <RowFixed>
                <Text fontWeight={500} fontSize={12}>
                  Your position
                </Text>
              </RowFixed>
            </FixedHeightRow>
            <FixedHeightRow onClick={() => setShowMore(!showMore)}>
              <RowFixed>
                <DoubleCurrencyLogo currency0={currency0} currency1={currency1} margin={true} size={20} />
                <Text fontWeight={500} fontSize={24}>
                  {currency0.symbol}/{currency1.symbol}
                </Text>
              </RowFixed>
            </FixedHeightRow>
            <AutoColumn gap="4px">
              <FixedHeightRow>
                <Text fontSize={14} fontWeight={500}>
                  POOL TOKENS:
                </Text>
                <Text fontSize={14} fontWeight={500}>
                  {userPoolBalance ? (
                    userPoolBalance?.toSignificant(4)?.length > 10 ? (
                      <MouseoverTooltip text={userPoolBalance?.toSignificant(4)}>
                        <p>{userPoolBalance?.toSignificant(4).slice(0, 5)}...</p>
                      </MouseoverTooltip>
                    ) : (
                      userPoolBalance.toSignificant(4)
                    )
                  ) : (
                    '-'
                  )}
                </Text>
              </FixedHeightRow>
              <FixedHeightRow>
                <Text fontSize={14} fontWeight={500}>
                  SHARE OF POOL:
                </Text>
                <Text fontSize={14} fontWeight={500}>
                  {poolTokenPercentage ? poolTokenPercentage.toFixed(6) + '%' : '-'}
                </Text>
              </FixedHeightRow>
              <FixedHeightRow>
                <Text fontSize={14} fontWeight={500}>
                  {currency0.symbol}:
                </Text>
                {token0Deposited ? (
                  <RowFixed>
                    <Text fontSize={14} fontWeight={500} marginLeft={'6px'}>
                      {token0Deposited?.toSignificant(6)}
                      {token0.priceUSD && !isMobile && (
                        <TokenPrice>
                          {'~$' + (token0.priceUSD * +token0Deposited?.toSignificant(6)).toFixed(3)}
                        </TokenPrice>
                      )}
                    </Text>
                  </RowFixed>
                ) : (
                  '-'
                )}
              </FixedHeightRow>
              <FixedHeightRow>
                <Text fontSize={14} fontWeight={500}>
                  {currency1.symbol}:
                </Text>
                {token1Deposited ? (
                  <RowFixed>
                    <Text fontSize={14} fontWeight={500} marginLeft={'6px'}>
                      {token1Deposited?.toSignificant(6)}
                      {token1.priceUSD && !isMobile && (
                        <TokenPrice>
                          {'~$' + (token1.priceUSD * +token1Deposited?.toSignificant(6)).toFixed(3)}
                        </TokenPrice>
                      )}
                    </Text>
                  </RowFixed>
                ) : (
                  '-'
                )}
              </FixedHeightRow>
            </AutoColumn>
          </AutoColumn>
        </GreyCard>
      )}
      {/* // ) : (
      //   <LightCard>
      //     <TYPE.subHeader style={{ textAlign: 'center' }}>
      //       <span role="img" aria-label="wizard-icon">
      //         ⭐️
      //       </span>{' '}
      //       By adding liquidity you&apos;ll earn 0.3% of all trades on this pair proportional to your share of the pool.
      //       Fees are added to the pool, accrue in real time and can be claimed by withdrawing your liquidity.
      //     </TYPE.subHeader>
      //   </LightCard>
      // )} */}
    </>
  );
}

function FullPositionCard({ pair, stakedBalance, setSelectedPair, expanded }: PositionCardProps) {
  const { account } = useActiveWeb3React();

  const currency0 = unwrappedToken(pair.token0);
  const currency1 = unwrappedToken(pair.token1);

  const [showMore, setShowMore] = useState(expanded || false);
  const userDefaultPoolBalance = useTokenBalance(account ?? undefined, pair.liquidityToken);
  const totalPoolTokens = useTotalSupply(pair.liquidityToken);

  // if staked balance balance provided, add to standard liquidity amount
  const userPoolBalance = stakedBalance ? userDefaultPoolBalance?.add(stakedBalance) : userDefaultPoolBalance;

  const poolTokenPercentage =
    !!userPoolBalance && !!totalPoolTokens && JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
      ? new Percent(userPoolBalance.raw, totalPoolTokens.raw)
      : undefined;

  const [token0Deposited, token1Deposited] =
    !!pair &&
    !!totalPoolTokens &&
    !!userPoolBalance &&
    // this condition is a short-circuit in the case where useTokenBalance updates sooner than useTotalSupply
    JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
      ? [
          pair.getLiquidityValue(pair.token0, totalPoolTokens, userPoolBalance, false),
          pair.getLiquidityValue(pair.token1, totalPoolTokens, userPoolBalance, false),
        ]
      : [undefined, undefined];

  return (
    <StyledPositionCard
      opened={!showMore}
      onClick={() => {
        if (!showMore) {
          setSelectedPair && setSelectedPair(pair);
          setShowMore(!showMore);
        }
      }}
      border={'none'}
      bgColor={'white'}
    >
      <AutoColumn gap="12px">
        <FixedHeightRow>
          <AutoRow>
            <DoubleCurrencyLogo currency0={currency0} currency1={currency1} size={24} />
            <Text fontWeight={600} fontSize={24}>
              {!currency0 || !currency1 ? <Dots>Loading</Dots> : `${currency0.symbol}/${currency1.symbol}`}
            </Text>
          </AutoRow>
          <RowFixed gap="8px">
            <ButtonEmpty padding="6px 8px" width="fit-content">
              {!showMore && (
                <>
                  <ChevronRight size="20" style={{ marginLeft: '0.4rem', marginTop: '0.2rem' }} />
                </>
              )}
            </ButtonEmpty>
          </RowFixed>
        </FixedHeightRow>

        {showMore && (
          <AutoColumn gap="8px">
            {pair && !isMobile && (
              <PoolPrice
                currencyA={currency0}
                currencyB={currency1}
                tokenA={pair.token0}
                tokenB={pair.token1}
                pair={pair}
              />
            )}
            <FixedHeightRow>
              <Text fontSize={16} fontWeight={500}>
                POOL TOKENS:
              </Text>
              <Text fontSize={16} fontWeight={500}>
                {userPoolBalance ? (
                  userPoolBalance?.toSignificant(4)?.length > 10 ? (
                    <MouseoverTooltip text={userPoolBalance?.toSignificant(4)}>
                      <p>{userPoolBalance?.toSignificant(4).slice(0, 5)}...</p>
                    </MouseoverTooltip>
                  ) : +userPoolBalance.toSignificant(4) > 1 ? (
                    numWithCommas(userPoolBalance.toSignificant(4))
                  ) : (
                    userPoolBalance.toSignificant(4)
                  )
                ) : (
                  '-'
                )}
              </Text>
            </FixedHeightRow>
            <FixedHeightRow>
              <Text fontSize={16} fontWeight={500}>
                SHARE OF POOL:
              </Text>
              <Text fontSize={16} fontWeight={500}>
                {poolTokenPercentage
                  ? (poolTokenPercentage.toFixed(2) === '0.00' ? '<0.01' : poolTokenPercentage.toFixed(2)) + '%'
                  : '-'}
              </Text>
            </FixedHeightRow>
            {stakedBalance && (
              <FixedHeightRow>
                <Text fontSize={16} fontWeight={500}>
                  Pool tokens in rewards pool:
                </Text>
                <Text fontSize={16} fontWeight={500}>
                  {stakedBalance.toSignificant(4)}
                </Text>
              </FixedHeightRow>
            )}
            <FixedHeightRow>
              <RowFixed>
                <Text fontSize={16} fontWeight={500}>
                  {currency0.symbol}:
                </Text>
              </RowFixed>
              {token0Deposited ? (
                <RowFixed>
                  <Text fontSize={16} fontWeight={500} marginLeft={'6px'}>
                    {+token0Deposited?.toSignificant(6) > 1
                      ? numWithCommas(token0Deposited?.toSignificant(6))
                      : token0Deposited?.toSignificant(6)}
                  </Text>
                </RowFixed>
              ) : (
                '-'
              )}
            </FixedHeightRow>

            <FixedHeightRow>
              <RowFixed>
                <Text fontSize={16} fontWeight={500}>
                  {currency1.symbol}:
                </Text>
              </RowFixed>
              {token1Deposited ? (
                <RowFixed>
                  <Text fontSize={16} fontWeight={500} marginLeft={'6px'}>
                    {+token1Deposited?.toSignificant(6) > 1
                      ? numWithCommas(token1Deposited?.toSignificant(6))
                      : token1Deposited?.toSignificant(6)}
                  </Text>
                </RowFixed>
              ) : (
                '-'
              )}
            </FixedHeightRow>

            {userDefaultPoolBalance && JSBI.greaterThan(userDefaultPoolBalance.raw, BIG_INT_ZERO) && (
              <RowBetween marginTop="10px">
                <ButtonPrimary
                  padding="8px"
                  as={Link}
                  to={`/add/${currencyId(currency0)}/${currencyId(currency1)}`}
                  width="48%"
                >
                  Add liquidity
                </ButtonPrimary>
                <BlackButton
                  style={{ fontWeight: 500 }}
                  padding="8px"
                  as={Link}
                  width="48%"
                  to={`/remove/${currencyId(currency0)}/${currencyId(currency1)}`}
                >
                  {isMobile ? 'Remove' : 'Remove Liquidity'}
                </BlackButton>
              </RowBetween>
            )}
            {stakedBalance && JSBI.greaterThan(stakedBalance.raw, BIG_INT_ZERO) && (
              <ButtonPrimary
                padding="8px"
                as={Link}
                to={`/uni/${currencyId(currency0)}/${currencyId(currency1)}`}
                width="100%"
              >
                Manage Liquidity in Rewards Pool
              </ButtonPrimary>
            )}
          </AutoColumn>
        )}
      </AutoColumn>
    </StyledPositionCard>
  );
}
export default React.memo(FullPositionCard);
