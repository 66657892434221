import { darken } from 'polished';
import React, { useCallback, useState } from 'react';
import { Currency, Pair } from 'stepex-sdk-mainnet';
import styled from 'styled-components';
import { ReactComponent as DropDown } from '../../assets/images/dropdown.svg';
import { useCurrencyBalance } from '../../state/wallet/hooks';
import { CloseIcon, TYPE } from '../../theme';
import CurrencyLogo from '../CurrencyLogo';
import DoubleCurrencyLogo from '../DoubleLogo';
import { Input as NumericalInput } from '../NumericalInput';
import { RowBetween } from '../Row';
import CurrencySearchModal from '../SearchModal/CurrencySearchModal';

import { useTokenData } from 'hooks/useTokenData';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { WFITFI } from '../../constants';
import { useActiveWeb3React } from '../../hooks';
import useTheme from '../../hooks/useTheme';

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
`;

const InputRow = styled.div<{ selected: boolean }>`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  margin-top: auto;
  padding: ${({ selected }) => (selected ? '0.8rem 0.9rem 0.8rem 1.1rem' : '0.8rem 0.9rem 0.8rem 1.1rem')};
`;

const CurrencySelect = styled.button`
  align-items: flex-start;
  height: 2.2rem;
  font-size: 20px;
  font-weight: 500;
  border: none;
  background-color: ${({ theme }) => theme.white};
  color: ${({ theme }) => theme.text1};
  border-radius: 12px;
  outline: none;
  padding: 0;
  cursor: pointer;
  user-select: none;
  border: none;
  transition: 0.2s;
`;

const LabelRow = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  color: ${({ theme }) => theme.text1};
  font-size: 0.75rem;
  line-height: 1rem;
  padding: 0.75rem 0.9rem 0 1rem;
  span:hover {
    cursor: pointer;
    color: ${({ theme }) => darken(0.2, theme.text2)};
  }
`;

const Aligner = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledDropDown = styled(DropDown)`
  margin: 0 0.25rem 0 0.5rem;
  height: 35%;

  path {
    stroke: ${({ theme }) => theme.text1};
    stroke-width: 1.5px;
  }
`;

const InputPanel = styled.div<{ hideInput?: boolean }>`
  ${({ theme }) => theme.flexColumnNoWrap}
  width: 420px;
  position: relative;
  min-height: 100px;
  max-height: 420px;
  overflow: auto;
  border-radius: ${({ hideInput }) => (hideInput ? '8px' : '20px')};
  background-color: ${({ theme }) => theme.white};
  z-index: 1;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
  `};
`;

const Container = styled.div<{ hideInput: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  border-radius: ${({ hideInput }) => (hideInput ? '8px' : '20px')};
`;

const StyledTokenName = styled.span<{ active?: boolean }>`
  ${({ active }) => (active ? '  margin: 0 0.25rem 0 0.55rem;' : '  margin: 0 0.25rem 0 0;')}
  font-size: 24px;
  font-weight: 500;
`;

const StyledBalanceMax = styled.button`
  padding: 0 0 0 0.5rem;
  background-color: ${({ theme }) => theme.white};
  border: 1px solid ${({ theme }) => theme.white};
  border-radius: 0.5rem;
  font-size: 12px;
  transition: 0.2s;

  font-weight: 600;
  cursor: pointer;
  color: ${({ theme }) => theme.primaryText1};

  :hover {
    color: ${({ theme }) => theme.primary1};
  }
  :focus {
    outline: none;
  }

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    margin-right: 0.5rem;
  `};
`;

const USDPrice = styled.span`
  position: absolute;
  left: 102%;
  bottom: 38px;
  color: ${({ theme }) => theme.text3};
  font-size: 0.65rem;
`;

interface CurrencyInputPanelProps {
  value: string;
  onUserInput: (value: string) => void;
  onMax?: () => void;
  showMaxButton: boolean;
  label?: string;
  onCurrencySelect?: (currency: Currency) => void;
  currency?: Currency | null;
  disableCurrencySelect?: boolean;
  hideBalance?: boolean;
  pair?: Pair | null;
  hideInput?: boolean;
  otherCurrency?: Currency | null;
  id: string;
  showCommonBases?: boolean;
  customBalanceText?: string;
}

export default function CurrencyInputPanel({
  value,
  onUserInput,
  onMax,
  showMaxButton,
  label = 'Input',
  onCurrencySelect,
  currency,
  disableCurrencySelect = false,
  hideBalance = false,
  pair = null, // used for double token logo
  hideInput = false,
  otherCurrency,
  id,
  showCommonBases,
  customBalanceText,
}: CurrencyInputPanelProps) {
  const { t } = useTranslation();

  const [modalOpen, setModalOpen] = useState(false);

  const { priceUSD } = useTokenData(
    //eslint-disable-next-line
    //@ts-ignore
    currency?.address?.toLowerCase() || ''
  );

  const { account } = useActiveWeb3React();
  const selectedCurrencyBalance = useCurrencyBalance(account ?? undefined, currency ?? undefined);
  const theme = useTheme();

  const handleDismissSearch = useCallback(() => {
    setModalOpen(false);
  }, [setModalOpen]);

  return (
    <InputWrapper>
      <InputPanel id={id}>
        <Container hideInput={hideInput}>
          {!hideInput && (
            <LabelRow>
              <RowBetween>
                <TYPE.body color={theme.text2} fontWeight={600} fontSize={12}>
                  {label}
                </TYPE.body>
                <div>
                  {account && (
                    <TYPE.body
                      onClick={onMax}
                      color={theme.text2}
                      fontWeight={600}
                      fontSize={12}
                      style={{ display: 'inline', cursor: 'pointer' }}
                    >
                      {!hideBalance && !modalOpen && !!currency && selectedCurrencyBalance
                        ? (customBalanceText ?? 'BALANCE: ') + selectedCurrencyBalance?.toSignificant(6)
                        : null}
                    </TYPE.body>
                  )}
                  {account && currency && !modalOpen && showMaxButton && label !== 'YOU BUY' ? (
                    <StyledBalanceMax onClick={onMax}>MAX</StyledBalanceMax>
                  ) : modalOpen ? (
                    <CloseIcon onClick={handleDismissSearch} />
                  ) : null}
                </div>
              </RowBetween>
            </LabelRow>
          )}
          {!modalOpen ? (
            <InputRow style={hideInput ? { padding: '0', borderRadius: '8px' } : {}} selected={disableCurrencySelect}>
              <CurrencySelect
                className="open-currency-select-button"
                onClick={() => {
                  if (!disableCurrencySelect) {
                    setModalOpen(true);
                  }
                }}
              >
                <Aligner>
                  {pair ? (
                    <DoubleCurrencyLogo currency0={pair.token0} currency1={pair.token1} size={24} margin={true} />
                  ) : currency ? (
                    <CurrencyLogo currency={currency} size={'24px'} />
                  ) : null}
                  {pair ? (
                    <StyledTokenName className="pair-name-container">
                      {pair?.token0.symbol}:{pair?.token1.symbol}
                    </StyledTokenName>
                  ) : (
                    <StyledTokenName className="token-symbol-container" active={Boolean(currency && currency.symbol)}>
                      {(currency && currency.symbol && currency.symbol.length > 20
                        ? currency.symbol.slice(0, 4) +
                          '...' +
                          currency.symbol.slice(currency.symbol.length - 5, currency.symbol.length)
                        : currency?.symbol) || t('CHOOSE TOKEN')}
                    </StyledTokenName>
                  )}
                  {!disableCurrencySelect && <StyledDropDown />}
                </Aligner>
              </CurrencySelect>
              {!hideInput && (
                <>
                  <NumericalInput
                    className="token-amount-input"
                    value={value}
                    align={'right'}
                    onUserInput={(val) => {
                      onUserInput(val);
                    }}
                  />
                </>
              )}
            </InputRow>
          ) : (
            !disableCurrencySelect &&
            onCurrencySelect && (
              <CurrencySearchModal
                isOpen={modalOpen}
                onDismiss={handleDismissSearch}
                onCurrencySelect={onCurrencySelect}
                selectedCurrency={currency}
                otherSelectedCurrency={otherCurrency}
                showCommonBases={showCommonBases}
                title={label}
              />
            )
          )}
        </Container>
      </InputPanel>
      {!!priceUSD && !isMobile && !!value && <USDPrice>~${Number(priceUSD * Number(value)).toFixed(2)}</USDPrice>}
    </InputWrapper>
  );
}
