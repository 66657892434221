import { Text } from 'rebass';
import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    height: 100%;
  `};
`;

export const ClickableText = styled(Text)`
  :hover {
    cursor: pointer;
  }
  font-size: 12px;
  color: ${({ theme }) => theme.black};
`;
export const MaxButton = styled.button<{ width: string }>`
  padding: 0.1rem 1rem;
  background-color: ${({ theme }) => theme.primary5};
  border: 1px solid transparent;
  border-radius: 2rem;
  font-size: 0.7rem;
  font-weight: 600;
  cursor: pointer;
  overflow: hidden;
  transition: 0.2s;

  :hover {
    opacity: 0.6;
  }

  :focus {
    border: 1px solid ${({ theme }) => theme.primary1};
    outline: none;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 0.25rem 0.5rem;
  `};
`;

export const Dots = styled.span`
  &::after {
    display: inline-block;
    animation: ellipsis 1.25s infinite;
    content: '.';
    width: 1em;
    text-align: left;
  }
  @keyframes ellipsis {
    0% {
      content: '.';
    }
    33% {
      content: '..';
    }
    66% {
      content: '...';
    }
  }
`;
