import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { getTimeframe } from 'utils';
import { client } from '../graph/client';
import { GLOBAL_CHART, TOKEN_CHART } from '../graph/queries';

const offsetVolumes = [
  '0x9ea3b5b4ec044b70375236a281986106457b20ef',
  '0x05934eba98486693aaec2d00b0e9ce918e37dc3f',
  '0x3d7e683fc9c86b4d653c9e47ca12517440fad14e',
  '0xfae9c647ad7d89e738aba720acf09af93dc535f7',
  '0x7296368fe9bcb25d3ecc19af13655b907818cc09',
];
export const timeframeOptions = {
  WEEK: '1 week',
  MONTH: '1 month',
  // THREE_MONTHS: '3 months',
  // YEAR: '1 year',
  HALF_YEAR: '6 months',
  ALL_TIME: 'All time',
};

const getTokenChartData = async (tokenAddress: string) => {
  let data: any = [];
  const utcEndTime = dayjs.utc();
  const utcStartTime = utcEndTime.subtract(1, 'year');
  const startTime = utcStartTime.startOf('minute').unix() - 1;

  try {
    let allFound = false;
    let skip = 0;
    while (!allFound) {
      const result = await client.query({
        query: TOKEN_CHART,
        variables: {
          tokenAddr: tokenAddress,
          skip,
        },
        fetchPolicy: 'cache-first',
      });
      if (result.data.tokenDayDatas.length < 1000) {
        allFound = true;
      }
      skip += 1000;
      data = data.concat(result.data.tokenDayDatas);
    }

    const dayIndexSet = new Set();
    const dayIndexArray: any[] = [];
    const oneDay = 24 * 60 * 60;
    data.forEach((dayData: any, i: number) => {
      // add the day index to the set of days
      dayIndexSet.add((data[i].date / oneDay).toFixed(0));
      dayIndexArray.push(data[i]);
      dayData.dailyVolumeUSD = parseFloat(dayData.dailyVolumeUSD);
    });

    // fill in empty days
    let timestamp = data[0] && data[0].date ? data[0].date : startTime;
    let latestLiquidityUSD = data[0] && data[0].totalLiquidityUSD;
    let latestPriceUSD = data[0] && data[0].priceUSD;
    let latestPairDatas = data[0] && data[0].mostLiquidPairs;
    let index = 1;
    while (timestamp < utcEndTime.startOf('minute').unix() - oneDay) {
      const nextDay = timestamp + oneDay;
      const currentDayIndex = (nextDay / oneDay).toFixed(0);
      if (!dayIndexSet.has(currentDayIndex)) {
        data.push({
          date: nextDay,
          dayString: nextDay,
          dailyVolumeUSD: 0,
          priceUSD: latestPriceUSD,
          totalLiquidityUSD: latestLiquidityUSD,
          mostLiquidPairs: latestPairDatas,
        });
      } else {
        latestLiquidityUSD = dayIndexArray[index].totalLiquidityUSD;
        latestPriceUSD = dayIndexArray[index].priceUSD;
        latestPairDatas = dayIndexArray[index].mostLiquidPairs;
        index = index + 1;
      }
      timestamp = nextDay;
    }
    data = data.sort((a: any, b: any) => (parseInt(a.date) > parseInt(b.date) ? 1 : -1));
  } catch (e) {
    console.log(e);
  }
  return data;
};

let checked = false;

const getChartData = async (oldestDateToFetch: any, offsetData: any) => {
  let data: any[] = [];
  const weeklyData: any[] = [];
  const utcEndTime = dayjs.utc();
  let skip = 0;
  let allFound = false;
  let error;

  try {
    if (error) {
      return [];
    }
    while (!allFound) {
      const result = await client.query({
        query: GLOBAL_CHART,
        variables: {
          startTime: oldestDateToFetch,
          skip,
        },
        fetchPolicy: 'cache-first',
      });
      skip += 1000;
      data = data.concat(result.data.stepExDayDatas);
      if (result.data.stepExDayDatas.length < 1000) {
        allFound = true;
      }
    }

    if (data) {
      const dayIndexSet = new Set();
      const dayIndexArray: any = [];
      const oneDay = 24 * 60 * 60;

      // for each day, parse the daily volume and format for chart array
      data.forEach((dayData, i) => {
        // add the day index to the set of days
        dayIndexSet.add((data[i].date / oneDay).toFixed(0));
        dayIndexArray.push(data[i]);
        dayData.dailyVolumeUSD = parseFloat(dayData.dailyVolumeUSD);
      });

      // fill in empty days ( there will be no day datas if no trades made that day )
      let timestamp = data[0].date ? data[0].date : oldestDateToFetch;
      let latestLiquidityUSD = data[0].totalLiquidityUSD;
      let latestDayDats = data[0].mostLiquidTokens;
      let index = 1;
      while (timestamp < utcEndTime.unix() - oneDay) {
        const nextDay = timestamp + oneDay;
        const currentDayIndex = (nextDay / oneDay).toFixed(0);

        if (!dayIndexSet.has(currentDayIndex)) {
          data.push({
            date: nextDay,
            dailyVolumeUSD: 0,
            totalLiquidityUSD: latestLiquidityUSD,
            mostLiquidTokens: latestDayDats,
          });
        } else {
          latestLiquidityUSD = dayIndexArray[index].totalLiquidityUSD;
          latestDayDats = dayIndexArray[index].mostLiquidTokens;
          index = index + 1;
        }
        timestamp = nextDay;
      }
    }

    // format weekly data for weekly sized chunks
    data = data.sort((a, b) => (parseInt(a.date) > parseInt(b.date) ? 1 : -1));
    let startIndexWeekly = -1;
    let currentWeek = -1;

    data.forEach((entry, i) => {
      const date = data[i].date;

      // hardcoded fix for offset volume
      offsetData &&
        !checked &&
        offsetData.map((dayData: any) => {
          if (dayData[date]) {
            data[i].dailyVolumeUSD = parseFloat(data[i].dailyVolumeUSD) - parseFloat(dayData[date].dailyVolumeUSD);
          }
          return true;
        });

      //eslint-disable-next-line
      //@ts-ignore
      const week = dayjs.utc(dayjs.unix(data[i].date)).week;
      if (week !== currentWeek) {
        currentWeek = week;
        startIndexWeekly++;
      }
      weeklyData[startIndexWeekly] = weeklyData[startIndexWeekly] || {};
      weeklyData[startIndexWeekly].date = data[i].date;
      weeklyData[startIndexWeekly].weeklyVolumeUSD =
        (weeklyData[startIndexWeekly].weeklyVolumeUSD ?? 0) + data[i].dailyVolumeUSD;
    });

    if (!checked) {
      checked = true;
    }
  } catch (e) {
    console.log(e);
    error = true;
  }
  return [data, weeklyData];
};

export function useTokenChartDataCombined(tokenAddresses: any[]) {
  const [chartData, setChartData] = useState<{ [x: string]: any }>();

  const datas = useMemo(() => {
    return (
      tokenAddresses &&
      tokenAddresses.reduce(function (acc, address) {
        acc[address] = chartData?.[address]?.chartData;
        return acc;
      }, {})
    );
  }, [tokenAddresses, chartData]);

  const isMissingData = useMemo(() => Object.values(datas).filter((val) => !val).length > 0, [datas]);

  const formattedByDate = useMemo(() => {
    return datas && !isMissingData
      ? Object.keys(datas).map(function (address) {
          const dayDatas = datas[address];
          return dayDatas?.reduce(function (acc: any, dayData: any) {
            acc[dayData.date] = dayData;
            return acc;
          }, {});
        }, {})
      : [];
  }, [datas, isMissingData]);

  useEffect(() => {
    async function fetchDatas() {
      Promise.all(
        tokenAddresses.map(async (address) => {
          return await getTokenChartData(address);
        })
      )
        .then((res) => {
          res &&
            res.map((result, i) => {
              const tokenAddress = tokenAddresses[i];
              setChartData({ [`${tokenAddress}`]: result });
              return true;
            });
        })
        .catch(() => {
          console.log('error fetching combined data');
        });
    }
    if (isMissingData) {
      fetchDatas();
    }
  }, [isMissingData, tokenAddresses]);
  return formattedByDate;
}

export function useGlobalChartData() {
  const [chartData, setChartData] = useState<{ newChartData: any[]; newWeeklyData: any[] }>();
  const [oldestDateFetch, setOldestDateFetched] = useState<number>();

  /**
   * Keep track of oldest date fetched. Used to
   * limit data fetched until its actually needed.
   * (dont fetch year long stuff unless year option selected)
   */
  useEffect(() => {
    // based on window, get starttime
    const startTime = getTimeframe('All time');

    if (!oldestDateFetch || startTime < oldestDateFetch) {
      setOldestDateFetched(startTime);
    }
  }, [oldestDateFetch]);

  // fix for rebass tokens

  const combinedData = useTokenChartDataCombined(offsetVolumes);

  /**
   * Fetch data if none fetched or older data is needed
   */
  useEffect(() => {
    async function fetchData() {
      // historical stuff for chart
      const [newChartData, newWeeklyData] = await getChartData(oldestDateFetch, combinedData);

      setChartData({ newChartData, newWeeklyData });
    }
    if (oldestDateFetch && !(chartData?.newChartData && chartData?.newWeeklyData) && combinedData) {
      fetchData();
    }
  }, [chartData, combinedData, oldestDateFetch]);

  return [chartData?.newChartData, chartData?.newWeeklyData];
}
