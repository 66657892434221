import { PriceRow, Quote, TradeInfo } from '../../components/swap/TradePrice';
import React from 'react';
import { createPortal } from 'react-dom';
import { Currency, Pair, Token } from 'stepex-sdk-mainnet';
import { numWithCommas } from 'utils/prices';
import { TOKENS_PAIRS } from 'constants/lists';
import { usePairData } from 'hooks/usePairData';

interface PriceProps {
  currencyA: Currency | undefined;
  currencyB: Currency | undefined;
  tokenA: Token | undefined;
  tokenB: Token | undefined;
  pair: Pair;
}

export const PoolPrice = ({ currencyB, currencyA, tokenA, pair, tokenB }: PriceProps) => {
  const wrapper = document.getElementById('body-wrapper');

  const lpToken = TOKENS_PAIRS.filter((pair) => {
    const token0symbol = tokenA?.symbol === 'FITFI' ? 'WFITFI' : tokenA?.symbol;
    const token1symbol = tokenB?.symbol === 'FITFI' ? 'WFITFI' : tokenB?.symbol;
    return (
      (token0symbol === pair.tokens[0].symbol || token0symbol === pair.tokens[1].symbol) &&
      (token1symbol === pair.tokens[1].symbol || token1symbol === pair.tokens[0].symbol)
    );
  });
  const { reserveUSD } = usePairData(lpToken[0]?.liquidityToken.address.toLowerCase());
  return wrapper
    ? createPortal(
        <TradeInfo>
          {reserveUSD && pair && tokenA && tokenB ? (
            <PriceRow>
              <Quote>
                <span>POOL SIZE</span> <span>$ {numWithCommas(Number(reserveUSD).toFixed(2))}</span>
              </Quote>

              <Quote>
                <span>
                  {currencyA?.symbol} per {currencyB?.symbol}
                </span>
                <span>{tokenA ? pair.priceOf(tokenA).toSignificant(6) : '-'}</span>
              </Quote>
              <Quote>
                <span>
                  {currencyB?.symbol} per {currencyA?.symbol}
                </span>
                <span> {tokenB ? pair.priceOf(tokenB).toSignificant(6) : '-'}</span>
              </Quote>
            </PriceRow>
          ) : null}
        </TradeInfo>,
        wrapper
      )
    : null;
};
