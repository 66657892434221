import { useWeb3React } from '@web3-react/core';
import React from 'react';
import { Redirect, RouteComponentProps, useHistory } from 'react-router-dom';
import AddLiquidity from './index';

export function RedirectToAddLiquidity() {
  return <Redirect to="/add/" />;
}

const OLD_PATH_STRUCTURE = /^(0x[a-fA-F0-9]{40})-(0x[a-fA-F0-9]{40})$/;
export function RedirectOldAddLiquidityPathStructure(props: RouteComponentProps<{ currencyIdA: string }>) {
  const { account } = useWeb3React();
  const history = useHistory();

  const {
    match: {
      params: { currencyIdA },
    },
  } = props;
  const match = currencyIdA.match(OLD_PATH_STRUCTURE);
  if (!account) {
    history.push('/pool');
    window.location.reload();
  }
  if (match?.length) {
    return <Redirect to={`/add/${match[1]}/${match[2]}`} />;
  }

  return <AddLiquidity {...props} />;
}

export function RedirectDuplicateTokenIds(props: RouteComponentProps<{ currencyIdA: string; currencyIdB: string }>) {
  const { account } = useWeb3React();
  const history = useHistory();

  const {
    match: {
      params: { currencyIdA, currencyIdB },
    },
  } = props;
  if (!account) {
    history.push('/pool');
    window.location.reload();
  }
  if (currencyIdA.toLowerCase() === currencyIdB.toLowerCase()) {
    return <Redirect to={`/add/${currencyIdA}`} />;
  }
  return <AddLiquidity {...props} />;
}
