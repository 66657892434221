import dayjs from 'dayjs';
import { blockClient, client } from 'graph/client';
import { FITFI_PRICE, GET_BLOCK, GET_BLOCKS, GLOBAL_DATA } from 'graph/queries';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { START_BLOCK } from '../../constants';

const TVLContext = createContext<any>({});

export function useTVLContext(): any {
  return useContext(TVLContext);
}

export async function getBlockFromTimestamp(timestamp: any) {
  const result = await blockClient.query({
    query: GET_BLOCK,
    variables: {
      timestampFrom: timestamp,
      timestampTo: timestamp + 600,
    },
    fetchPolicy: 'cache-first',
  });
  return result?.data?.blocks?.[0]?.number;
}

export const getPercentChange = (valueNow: any, value24HoursAgo: any) => {
  const adjustedPercentChange =
    ((parseFloat(valueNow) - parseFloat(value24HoursAgo)) / parseFloat(value24HoursAgo)) * 100;
  if (isNaN(adjustedPercentChange) || !isFinite(adjustedPercentChange)) {
    return 0;
  }
  return adjustedPercentChange;
};

const getFitfiPrice = async () => {
  const utcCurrentTime = dayjs();
  const utcOneDayBack = utcCurrentTime.subtract(1, 'day').startOf('minute').unix();

  let fitfiPrice = 0;
  let fitfiPriceOneDay = 0;
  let priceChangeETH = 0;

  try {
    const oneDayBlock = await getBlockFromTimestamp(utcOneDayBack);

    const result = await client.query({
      query: FITFI_PRICE(),
      fetchPolicy: 'cache-first',
    });

    const resultOneDay = await client.query({
      query: FITFI_PRICE(oneDayBlock),
      fetchPolicy: 'cache-first',
    });

    const currentPrice = result?.data?.bundles[0]?.fitfiPrice;
    const oneDayBackPrice = resultOneDay?.data?.bundles[0]?.fitfiPrice;
    priceChangeETH = getPercentChange(currentPrice, oneDayBackPrice);
    fitfiPrice = currentPrice;
    fitfiPriceOneDay = oneDayBackPrice;
  } catch (e) {
    console.log(e);
    return [0, 0, 0];
  }

  return [fitfiPrice, fitfiPriceOneDay, priceChangeETH];
};

export function useFitfiPrice() {
  const [state, setState] = useState<{ newPrice: number; oneDayPrice: number; priceChange: number }>();

  useEffect(() => {
    async function checkForfitfiPrice() {
      if (state?.newPrice === undefined) {
        const [newPrice, oneDayPrice, priceChange] = await getFitfiPrice();
        setState({ newPrice, oneDayPrice, priceChange });
      }
    }
    checkForfitfiPrice();
  }, [state]);

  return [state?.newPrice, state?.oneDayPrice];
}

export async function splitQuery(query: any, localClient: any, vars: any, list: any, skipCount = 100): Promise<any> {
  let fetchedData = {};
  let allFound = false;
  let skip = 0;

  while (!allFound) {
    let end = list.length;
    if (skip + skipCount < list.length) {
      end = skip + skipCount;
    }
    const sliced = list.slice(skip, end);
    const result = await localClient.query({
      query: query(...vars, sliced),
      fetchPolicy: 'cache-first',
    });
    fetchedData = {
      ...fetchedData,
      ...result.data,
    };
    if (Object.keys(result.data).length < skipCount || skip + skipCount > list.length) {
      allFound = true;
    } else {
      skip += skipCount;
    }
  }

  return fetchedData;
}

export async function getBlocksFromTimestamps(timestamps: any[], skipCount = 500) {
  if (timestamps?.length === 0) {
    return [];
  }

  const fetchedData = await splitQuery(GET_BLOCKS, blockClient, [], timestamps, skipCount);
  const blocks = [];
  if (fetchedData) {
    for (const t in fetchedData) {
      if (fetchedData[t].length > 0) {
        blocks.push({
          timestamp: t.split('t')[1],
          number: fetchedData[t][0]['number'],
        });
      }
    }
  }

  return blocks;
}

export const get2DayPercentChange = (valueNow: any, value24HoursAgo: any, value48HoursAgo: any): any => {
  // get volume info for both 24 hour periods
  const currentChange = parseFloat(valueNow) - parseFloat(value24HoursAgo);
  const previousChange = parseFloat(value24HoursAgo) - parseFloat(value48HoursAgo);

  const adjustedPercentChange =
    (parseFloat(`${currentChange - previousChange}`) / parseFloat(`${previousChange}`)) * 100;

  if (isNaN(adjustedPercentChange) || !isFinite(adjustedPercentChange)) {
    return [currentChange, 0];
  }
  return [currentChange, adjustedPercentChange];
};

async function getGlobalData(fitfiPrice: any, oldfitfiPrice: any) {
  // data for each day , historic data used for % changes
  let data: any = {};
  let oneDayData: any = {};
  let twoDayData: any = {};

  try {
    // get timestamps for the days
    const utcCurrentTime = dayjs();
    const utcOneDayBack = utcCurrentTime.subtract(1, 'day').unix();
    const utcTwoDaysBack = utcCurrentTime.subtract(2, 'day').unix();
    const utcOneWeekBack = utcCurrentTime.subtract(1, 'week').unix();
    const utcTwoWeeksBack = utcCurrentTime.subtract(2, 'week').unix();

    // get the blocks needed for time travel queries
    const [oneDayBlock, twoDayBlock, oneWeekBlock, twoWeekBlock] = await getBlocksFromTimestamps([
      utcOneDayBack,
      utcTwoDaysBack,
      utcOneWeekBack,
      utcTwoWeeksBack,
    ]);

    // fetch the global data
    const result = await client.query({
      query: GLOBAL_DATA(),
      fetchPolicy: 'cache-first',
    });
    data = result.data.stepExFactories[0];
    // fetch the historical data
    const oneDayResult = await client.query({
      query: GLOBAL_DATA(oneDayBlock?.number),
      fetchPolicy: 'cache-first',
    });
    oneDayData = oneDayResult.data.stepExFactories[0];

    const twoDayResult = await client.query({
      query: GLOBAL_DATA(twoDayBlock?.number),
      fetchPolicy: 'cache-first',
    });
    twoDayData = twoDayResult.data.stepExFactories[0];

    const oneWeekResult = await client.query({
      query: GLOBAL_DATA(oneWeekBlock?.number),
      fetchPolicy: 'cache-first',
    });
    const oneWeekData = oneWeekResult.data.stepExFactories[0];

    const twoWeekResult = await client.query({
      query: GLOBAL_DATA(twoWeekBlock?.number <= START_BLOCK ? START_BLOCK : twoWeekBlock?.number),
      fetchPolicy: 'cache-first',
    });
    const twoWeekData = twoWeekResult.data.stepExFactories[0];
    if (data && oneDayData && twoDayData && twoWeekData) {
      const [oneDayVolumeUSD, volumeChangeUSD] = get2DayPercentChange(
        data.totalVolumeUSD,
        oneDayData.totalVolumeUSD,
        twoDayData.totalVolumeUSD
      );
      const [oneWeekVolume, weeklyVolumeChange] = get2DayPercentChange(
        data.totalVolumeUSD,
        oneWeekData.totalVolumeUSD,
        twoWeekData.totalVolumeUSD
      );

      const [oneDayTxns, txnChange] = get2DayPercentChange(
        data.txCount,
        oneDayData.txCount ? oneDayData.txCount : 0,
        twoDayData.txCount ? twoDayData.txCount : 0
      );

      // format the total liquidity in USD
      data.totalLiquidityUSD = data.totalLiquidityFITFI * fitfiPrice;
      const liquidityChangeUSD = getPercentChange(
        data.totalLiquidityFITFI * fitfiPrice,
        oneDayData.totalLiquidityFITFI * oldfitfiPrice
      );

      // add relevant fields with the calculated amounts
      data.oneDayVolumeUSD = oneDayVolumeUSD;
      data.oneWeekVolume = oneWeekVolume;
      data.weeklyVolumeChange = weeklyVolumeChange;
      data.volumeChangeUSD = volumeChangeUSD;
      data.liquidityChangeUSD = liquidityChangeUSD;
      data.oneDayTxns = oneDayTxns;
      data.txnChange = txnChange;
    }
  } catch (e) {
    console.log(e);
  }

  return data;
}

export default function TVLProvider({ children }: any) {
  const [data, setData] = useState<any>();
  const [fitfiPrice, oldFitfiPrice] = useFitfiPrice();

  const fetchPairs = useCallback(async () => {
    const globalData = await getGlobalData(fitfiPrice, oldFitfiPrice);

    setData(globalData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fitfiPrice, oldFitfiPrice]);

  useEffect(() => {
    if (fitfiPrice && oldFitfiPrice) {
      fetchPairs();
    }
  }, [fitfiPrice, oldFitfiPrice, fetchPairs]);

  const value = useMemo(
    () => ({
      ...data,
    }),
    [data]
  );
  //eslint-disable-next-line
  //ts-ignore
  return <TVLContext.Provider value={value}>{children}</TVLContext.Provider>;
}
