import Row from 'components/Row';
import SettingsTab from 'components/Settings';
import { darken } from 'polished';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { AppDispatch } from 'state';
import { resetMintState } from 'state/mint/actions';
import styled from 'styled-components';

export const BodyWrapper = styled.div`
  position: relative;
  max-width: 420px;
  width: 100%;
  border-radius: 1.6rem;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 40px;
    min-height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  `};

  @media (min-width: 1450px) {
    zoom: 1.3;
  }
  @media (min-width: 1980px) {
    zoom: 2;
  }
`;

const HeaderLinks = styled(Row)`
  width: auto;
  max-width: 420px;
  margin: 0 0 16px 0;
  border-radius: 0.8rem;
  display: flex;
  gap: 8px;
  z-index: 100;
  @media (min-width: 1450px) {
    zoom: 1.3;
  }
  @media (min-width: 1980px) {
    zoom: 2;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    position: absolute;
    top: 0;
    padding: 1.5rem;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 0;
  `};
`;
const activeClassName = 'ACTIVE';

const StyledNavLink = styled(NavLink).attrs({
  activeClassName,
})`
  ${({ theme }) => theme.flexRowNoWrap}
  border-radius: 64px;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.text2};
  font-size: 12px;
  width: 60px;
  padding: 0.3rem 0.6rem;
  font-weight: 600;
  transition: 0.3s;
  text-align: center;
  background-color: ${({ theme }) => theme.white};
  display: block;

  &:not(:last-child) {
    margin-right: 0.16rem;
  }

  &.${activeClassName} {
    color: ${({ theme }) => theme.white};
    background-color: ${({ theme }) => theme.black};
    :hover,
    :focus {
      color: ${({ theme }) => darken(0.1, theme.white)};
    }
  }

  :hover,
  :focus {
    color: ${({ theme }) => darken(0.1, theme.text1)};
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.bg3};

    &:not(:last-child) {
      margin-right: 2%;
    }
  `};
`;

const BackButton = styled(NavLink).attrs({
  activeClassName,
})`
  ${({ theme }) => theme.flexRowNoWrap}
  margin-left: auto;
  border-radius: 64px;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.text2};
  font-size: 12px;
  min-width: 60px;
  padding: 0.3rem 0.6rem;
  font-weight: 600;
  transition: 0.3s;
  text-align: center;
  background-color: ${({ theme }) => theme.white};
  display: block;

  &.${activeClassName} {
    display: none;
  }
`;

export default function AppBody({ children, goBack }: { children: React.ReactNode; goBack?: (() => void) | null }) {
  const dispatch = useDispatch<AppDispatch>();
  const showSettings = window.location.hash.includes('/swap');
  return (
    <div
      style={{
        alignSelf: isMobile ? 'auto' : 'end',
        width: isMobile ? '100%' : '70%',
        maxHeight: isMobile ? 'auto' : 'calc(100vh - 120px)',
      }}
    >
      <HeaderLinks>
        <StyledNavLink id={`swap-nav-link`} to={'/swap'}>
          SWAP
        </StyledNavLink>
        <StyledNavLink
          id={`pool-nav-link`}
          to={'/pool'}
          isActive={(match, { pathname }) =>
            Boolean(match) ||
            pathname.startsWith('/add') ||
            pathname.startsWith('/remove') ||
            pathname.startsWith('/create') ||
            pathname.startsWith('/find')
          }
        >
          POOL
        </StyledNavLink>
        <BackButton
          to="/pool"
          onClick={() => {
            goBack ? goBack() : dispatch(resetMintState());
          }}
          isActive={(match, { pathname }) =>
            Boolean(goBack) ? false : pathname.startsWith('/pool') || pathname.startsWith('/swap')
          }
        >
          GO BACK
        </BackButton>
        {showSettings && <SettingsTab />}
      </HeaderLinks>
      <BodyWrapper>{children}</BodyWrapper>
    </div>
  );
}
