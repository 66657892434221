/* eslint @typescript-eslint/no-var-requires: "off" */
import { AbstractConnector } from '@web3-react/abstract-connector';
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';
import { ReactComponent as Close } from '../../assets/images/x.svg';
import { BlackButton, ButtonPrimary } from 'components/Button';

// import { OVERLAY_READY } from '../../connectors/Fortmatic';
import { SUPPORTED_WALLETS } from '../../constants';
import usePrevious from '../../hooks/usePrevious';
import { ApplicationModal } from '../../state/application/actions';
import { useModalOpen, useWalletModalToggle } from '../../state/application/hooks';

import Modal from '../Modal';
import Option from './Option';
import PendingView from './PendingView';
import { injected } from 'connectors';

const CloseIcon = styled.div`
  position: absolute;
  right: 1rem;
  top: 1rem;

  &:hover {
    cursor: pointer;
    opacity: 0.5;
  }
`;

const CloseColor = styled(Close)`
  path {
    stroke: ${({ theme }) => theme.text4};
  }
`;

const Wrapper = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap}
  margin: 0;
  padding: 0;
  width: 100%;
`;

const HeaderRow = styled.div`
  ${({ theme }) => theme.flexRowNoWrap};
  padding: 1rem 1rem;
  font-weight: 500;
  color: ${(props) => (props.color === 'blue' ? ({ theme }) => theme.black : 'inherit')};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 1rem;
  `};
`;

const ContentWrapper = styled.div`
  background-color: #fdfdfd;
  padding: 2rem;
  border-radius: 20px;

  ${({ theme }) => theme.mediaWidth.upToMedium`padding: 1rem`};
`;

const CloseButton = styled(BlackButton)`
  width: 69px;
  margin-left: auto;
  font-size: 12px;
  height: 28px;
  padding: 12px;
`;

const UpperSection = styled.div`
  position: relative;

  h5 {
    margin: 0;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    font-weight: 400;
  }

  h5:last-child {
    margin-bottom: 0px;
  }

  h4 {
    margin-top: 0;
    font-weight: 500;
  }
`;

const OptionGrid = styled.div`
  display: grid;
  grid-gap: 10px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    grid-template-columns: 1fr;
    grid-gap: 10px;
  `};
`;

const WALLET_VIEWS = {
  OPTIONS: 'options',
  OPTIONS_SECONDARY: 'options_secondary',
  ACCOUNT: 'account',
  PENDING: 'pending',
};

export default function WalletModal() {
  // important that these are destructed from the account-specific web3-react context
  const { active, account, connector, activate, error } = useWeb3React();
  const [walletView, setWalletView] = useState(WALLET_VIEWS.ACCOUNT);

  const [pendingWallet, setPendingWallet] = useState<AbstractConnector | undefined>();

  const [pendingError, setPendingError] = useState<boolean>();

  const walletModalOpen = useModalOpen(ApplicationModal.WALLET);
  const toggleWalletModal = useWalletModalToggle();

  const previousAccount = usePrevious(account);

  // close on connection, when logged out before
  useEffect(() => {
    if (account && !previousAccount && walletModalOpen) {
      toggleWalletModal();
    }
  }, [account, previousAccount, toggleWalletModal, walletModalOpen]);

  // always reset to account view
  useEffect(() => {
    if (walletModalOpen) {
      setPendingError(false);
      setWalletView(WALLET_VIEWS.ACCOUNT);
    }
  }, [walletModalOpen]);

  // close modal when a connection is successful
  const activePrevious = usePrevious(active);
  const connectorPrevious = usePrevious(connector);
  useEffect(() => {
    if (walletModalOpen && ((active && !activePrevious) || (connector && connector !== connectorPrevious && !error))) {
      setWalletView(WALLET_VIEWS.ACCOUNT);
    }
  }, [setWalletView, active, error, connector, walletModalOpen, activePrevious, connectorPrevious]);

  const tryActivation = async (connector: AbstractConnector | undefined) => {
    setPendingWallet(connector); // set wallet for pending view
    setWalletView(WALLET_VIEWS.PENDING);

    // if the connector is walletconnect and the user has already tried to connect, manually reset the connector
    if (connector instanceof WalletConnectConnector && connector.walletConnectProvider?.wc?.uri) {
      connector.walletConnectProvider = undefined;
    }

    connector &&
      activate(connector, undefined, true).catch((error) => {
        if (error instanceof UnsupportedChainIdError) {
          activate(connector); // a little janky...can't use setError because the connector isn't set
        } else {
          setPendingError(true);
        }
      });
  };

  // get wallets user can switch too, depending on device/browser
  function getOptions() {
    const isMetamask = window.ethereum && window.ethereum.isMetaMask;

    return Object.keys(SUPPORTED_WALLETS).map((key) => {
      const option = SUPPORTED_WALLETS[key];
      // check for mobile options
      if (isMobile) {
        if ((!window.web3 && !window.ethereum && option.mobile) || (window.ethereum && option.name === 'MetaMask')) {
          return (
            <Option
              onClick={() => {
                option.connector !== connector && !option.href && tryActivation(option.connector);
                localStorage.setItem('logined', 'true');
              }}
              id={`connect-${key}`}
              key={key}
              active={option.connector && option.connector === connector}
              color={option.color}
              link={
                option.name === 'MetaMask' && !window.ethereum
                  ? `https://metamask.app.link/dapp/${window.location.href}`
                  : option.href
              }
              header={option.name}
              subheader={null}
              icon={require('../../assets/images/' + option.iconName).default}
            />
          );
        }
        if (option.connector === injected) {
          // don't show injected if there's no injected provider
          if (!(window.web3 || window.ethereum)) {
            if (option.name === 'MetaMask') {
              return (
                <Option
                  id={`connect-${key}`}
                  key={key}
                  color={'#E8831D'}
                  header={option.name}
                  subheader={null}
                  link={`https://metamask.app.link/dapp/${window.location.href}`}
                  icon={require('../../assets/images/' + option.iconName).default}
                />
              );
            } else {
              return null; //dont want to return install twice
            }
          }
          // don't return metamask if injected provider isn't metamask
          else if (option.name === 'MetaMask' && !isMetamask) {
            return null;
          }
          // likewise for generic
          else if (option.name === 'Injected' && isMetamask) {
            return null;
          }
        }
        return null;
      }
      // likewise for generic
      if (option.name === 'Injected') {
        return null;
      }

      // return rest of options
      return (
        !isMobile &&
        !option.mobileOnly && (
          <Option
            id={`connect-${key}`}
            onClick={() => {
              option.connector === connector
                ? setWalletView(WALLET_VIEWS.ACCOUNT)
                : !option.href && tryActivation(option.connector);
              localStorage.setItem('logined', 'true');
            }}
            key={key}
            active={option.connector === connector}
            color={option.color}
            link={
              option.name === 'MetaMask' && !window.ethereum
                ? `https://metamask.app.link/dapp/${window.location.href}`
                : option.href
            }
            header={option.name}
            subheader={null} //use option.descriptio to bring back multi-line
            icon={require('../../assets/images/' + option.iconName).default}
          />
        )
      );
    });
  }

  function getModalContent() {
    async function addNetwork() {
      if (!window.ethereum) {
        console.log("Couldn't find wallet");
      }
      await window.ethereum?.request({
        method: 'wallet_addEthereumChain',
        params: [
          {
            chainId: `0x${Number(1234).toString(16)}`,
            chainName: 'Step Network [MAINNET]',
            rpcUrls: ['https://rpc.step.network/'],
            blockExplorerUrls: ['https://stepscan.io/'],
            nativeCurrency: {
              name: 'FITFI',
              symbol: 'FITFI',
              decimals: 18,
            },
          },
        ],
      });
    }
    async function switchNetwork() {
      if (!window.ethereum) {
        console.log("Couldn't find wallet");
        isMobile && alert('Change network in your application');
      }

      try {
        await window.ethereum?.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: `0x${Number(1234).toString(16)}` }],
        });
        window.location.reload();
      } catch (error) {
        //eslint-disable-next-line
        //@ts-ignore
        if (error instanceof UnsupportedChainIdError || error.code === 4902 || error.code === -32603) addNetwork();
        console.error('error while switching networks');
      }
    }

    if (error) {
      return (
        <UpperSection>
          <CloseIcon onClick={toggleWalletModal}>
            <CloseColor />
          </CloseIcon>
          <HeaderRow>{error instanceof UnsupportedChainIdError ? 'Wrong Network' : 'Error connecting'}</HeaderRow>
          <ContentWrapper>
            {error instanceof UnsupportedChainIdError ? (
              <>
                <h5>Please connect to the Step Network.</h5>
                <ButtonPrimary onClick={switchNetwork}>Switch to Step Network</ButtonPrimary>
              </>
            ) : (
              'Error connecting. Try refreshing the page.'
            )}
          </ContentWrapper>
        </UpperSection>
      );
    }
    return (
      <UpperSection>
        <HeaderRow>
          <CloseButton onClick={toggleWalletModal}>CLOSE</CloseButton>
        </HeaderRow>
        <ContentWrapper>
          {walletView === WALLET_VIEWS.PENDING ? (
            <PendingView
              connector={pendingWallet}
              error={pendingError}
              setPendingError={setPendingError}
              tryActivation={tryActivation}
            />
          ) : (
            <OptionGrid>{getOptions()}</OptionGrid>
          )}
        </ContentWrapper>
      </UpperSection>
    );
  }

  return (
    <Modal isOpen={walletModalOpen} onDismiss={toggleWalletModal} minHeight={false} maxHeight={90}>
      <Wrapper>{getModalContent()}</Wrapper>
    </Modal>
  );
}
