import { FACTORY_ADDRESS } from '../constants';
import gql from 'graphql-tag';

export const TOTAL_SPEX_COMISSIONS = gql`
  {
    accountBalances(where: { account: "0x9f76d1b175bb36a830c503783bdcbd03d3958786" }) {
      amount
    }
  }
`;

export const DAILY_SPEX_COMISSIONS = gql`
  {
    accountBalanceDailySnapshots(where: { account: "0x9f76d1b175bb36a830c503783bdcbd03d3958786" }) {
      amount
    }
  }
`;

export const GET_BLOCK = gql`
  query blocks($timestampFrom: Int!, $timestampTo: Int!) {
    blocks(
      first: 1
      orderBy: timestamp
      orderDirection: asc
      where: { timestamp_gt: $timestampFrom, timestamp_lt: $timestampTo }
    ) {
      id
      number
      timestamp
    }
  }
`;

export const FITFI_PRICE = (block?: number) => {
  const queryString = block
    ? `
    query bundles {
      bundles(where: { id: 1 } block: {${block ? `number: ${block}` : ''}}) {
        id
        fitfiPrice
      }
    }
  `
    : ` query bundles {
      bundles(where: { id: 1 }) {
        id
        fitfiPrice
      }
    }
  `;
  return gql(queryString);
};

export const GLOBAL_DATA = (block?: number) => {
  const queryString = ` query stepExFactories {
      stepExFactories(
       ${block ? `block: { number: ${block}}` : ``} 
       where: { id: "${FACTORY_ADDRESS.toLowerCase()}" }) {
        id
        totalVolumeUSD
        totalVolumeFITFI
        untrackedVolumeUSD
        totalLiquidityUSD
        totalLiquidityFITFI
        txCount
        pairCount
      }
    }`;
  return gql(queryString);
};

export const GET_BLOCKS = (timestamps: any[]) => {
  let queryString = 'query blocks {';
  queryString += timestamps.map((timestamp) => {
    return `t${timestamp}:blocks(first: 1, orderBy: timestamp, orderDirection: desc, where: { timestamp_gt: ${timestamp}, timestamp_lt: ${
      timestamp + 600
    } }) {
      number
    }`;
  });
  queryString += '}';
  return gql(queryString);
};

export const TOKEN_CHART = gql`
  query tokenDayDatas($tokenAddr: String!, $skip: Int!) {
    tokenDayDatas(first: 1000, skip: $skip, orderBy: date, orderDirection: asc, where: { token: $tokenAddr }) {
      id
      date
      priceUSD
      totalLiquidityToken
      totalLiquidityUSD
      totalLiquidityFITFI
      dailyVolumeFITFI
      dailyVolumeToken
      dailyVolumeUSD
    }
  }
`;

export const GLOBAL_CHART = gql`
  query stepExDayDatas($startTime: Int!, $skip: Int!) {
    stepExDayDatas(first: 1000, skip: $skip, where: { date_gt: $startTime }, orderBy: date, orderDirection: asc) {
      id
      date
      totalVolumeUSD
      dailyVolumeUSD
      dailyVolumeFITFI
      totalLiquidityUSD
      totalLiquidityFITFI
    }
  }
`;

const PairFields = `
  fragment PairFields on Pair {
    id
    txCount
    token0 {
      id
      symbol
      name
      totalLiquidity
      derivedFITFI
    }
    token1 {
      id
      symbol
      name
      totalLiquidity
      derivedFITFI
    }
    reserve0
    reserve1
    reserveUSD
    totalSupply
    trackedReserveFITFI
    reserveFITFI
    volumeUSD
    untrackedVolumeUSD
    token0Price
    token1Price
    createdAtTimestamp
  }
`;

export const PAIRS_BULK = gql`
  ${PairFields}
  query pairs($allPairs: [Bytes]!) {
    pairs(first: 500, where: { id_in: $allPairs }, orderBy: trackedReserveFITFI, orderDirection: desc) {
      ...PairFields
    }
  }
`;

export const PAIRS_HISTORICAL_BULK = (block: number, pairs: any[]) => {
  let pairsString = `[`;
  pairs.map((pair) => {
    return (pairsString += `"${pair}"`);
  });
  pairsString += ']';
  const queryString = `
  query pairs {
    pairs(first: 200, where: {id_in: ${pairsString}}, block: {number: ${block}}, orderBy: trackedReserveFITFI, orderDirection: desc) {
      id
      reserveUSD
      trackedReserveFITFI
      volumeUSD
      untrackedVolumeUSD
    }
  }
  `;
  return gql(queryString);
};

export const PAIR_DATA = (pairAddress: string, block: number) => {
  const queryString = `
    ${PairFields}
    query pairs {
      pairs(${block ? `block: {number: ${block}}` : ``} where: { id: "${pairAddress}"} ) {
        ...PairFields
      }
    }`;
  return gql(queryString);
};

const TokenFields = `
  fragment TokenFields on Token {
    id
    name
    symbol
    derivedFITFI
    tradeVolume
    tradeVolumeUSD
    untrackedVolumeUSD
    totalLiquidity
    txCount
  }
`;

export const TOKEN_DATA = (tokenAddress: string, block?: number) => {
  const queryString = `
    ${TokenFields}
    query tokens {
      tokens(${block ? `block : {number: ${block}}` : ``} where: {id:"${tokenAddress}"}) {
        ...TokenFields
      }
      pairs0: pairs(where: {token0: "${tokenAddress}"}, first: 50, orderBy: reserveUSD, orderDirection: desc){
        id
      }
      pairs1: pairs(where: {token1: "${tokenAddress}"}, first: 50, orderBy: reserveUSD, orderDirection: desc){
        id
      }
    }
  `;
  return gql(queryString);
};
