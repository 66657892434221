import { BigNumber } from '@ethersproject/bignumber';
import { DEFAULT_DEADLINE_FROM_NOW } from '../constants';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../state';
import useCurrentBlockTimestamp from './useCurrentBlockTimestamp';

// combines the block timestamp with the user setting to give the deadline that should be used for any submitted transaction
export default function useTransactionDeadline(): BigNumber | undefined {
  const ttl = useSelector<AppState, number>((state) => state.user.userDeadline);
  const blockTimestamp = useCurrentBlockTimestamp();
  return useMemo(() => {
    // if (blockTimestamp && ttl) return blockTimestamp.add(ttl);
    if (blockTimestamp && ttl) return BigNumber.from(Date.now() + DEFAULT_DEADLINE_FROM_NOW);
    return undefined;
  }, [blockTimestamp, ttl]);
}
