import { TokenAmount, Pair, Currency } from 'stepex-sdk-mainnet';
import { useMemo, useState, useEffect } from 'react';
import { abi as IUniswapV2PairABI } from '@uniswap/v2-core/build/IUniswapV2Pair.json';
import { Interface } from '@ethersproject/abi';
import { useActiveWeb3React } from '../hooks';

import { useMultipleContractSingleData } from '../state/multicall/hooks';
import { wrappedCurrency } from '../utils/wrappedCurrency';
import { useFactoryContract } from 'hooks/useContract';
import { getPairAddresses } from 'utils';

const PAIR_INTERFACE = new Interface(IUniswapV2PairABI);
const pairs: { [key: string]: string } = {};
export enum PairState {
  LOADING,
  NOT_EXISTS,
  EXISTS,
  INVALID,
}
let tokencache = '';
export function usePairs(currencies: [Currency | undefined, Currency | undefined][]): [PairState, Pair | null][] {
  const { chainId } = useActiveWeb3React();
  const [addresses, setAddresses] = useState<string[] | undefined[]>([undefined]);
  const factory = useFactoryContract();
  const tokens = useMemo(
    () =>
      currencies.map(([currencyA, currencyB]) => [
        wrappedCurrency(currencyA, chainId),
        wrappedCurrency(currencyB, chainId),
      ]),
    [chainId, currencies]
  );

  const pairAddresses = useMemo(async () => {
    const data = await Promise.all(
      tokens.map(async ([tokenA, tokenB]) => {
        const pairAddress =
          tokenA && tokenB && !tokenA.equals(tokenB) ? await getPairAddresses(factory, tokenA, tokenB) : undefined;
        if (tokenA && tokenB) pairs[tokenA.address + tokenB.address] = pairAddress;
        return pairAddress;
      })
    );
    tokencache = JSON.stringify(tokens);
    return data;
  }, [factory, tokens]);

  useEffect(() => {
    tokens.length &&
      tokens[0][0] &&
      tokens[0][1] &&
      JSON.stringify(tokens) !== tokencache &&
      pairAddresses.then((add) => {
        setAddresses(add);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokens]);

  // computePairAddress
  const results = useMultipleContractSingleData(addresses, PAIR_INTERFACE, 'getReserves');
  /* A debug statement. */
  console.groupEnd();

  return useMemo(() => {
    return results.map((result, i) => {
      const { result: reserves, loading } = result;
      if (!tokens[i] || !tokens[i][0]) return [PairState.NOT_EXISTS, null];

      const tokenA = tokens[i][0];
      const tokenB = tokens[i][1];

      if (loading) return [PairState.LOADING, null];
      if (!tokenA || !tokenB || tokenA.equals(tokenB)) return [PairState.INVALID, null];
      if (!reserves) return [PairState.NOT_EXISTS, null];
      const { reserve0, reserve1 } = reserves;
      const [token0, token1] = tokenA.sortsBefore(tokenB) ? [tokenA, tokenB] : [tokenB, tokenA];

      return [
        PairState.EXISTS,
        new Pair(
          new TokenAmount(token0, reserve0.toString()),
          new TokenAmount(token1, reserve1.toString()),
          pairs[token0.address + token1.address]
        ),
      ];
    });
  }, [results, tokens]);
}

export function usePair(tokenA?: Currency, tokenB?: Currency): [PairState, Pair | null] {
  return usePairs([[tokenA, tokenB]])[0];
}
