import React from 'react';
import Modal from 'components/Modal';
import { AutoColumn } from 'components/Column';
import { TransactionErrorContent } from 'components/TransactionConfirmationModal';

export default function SlippageSwapModal({ isOpen, onDismiss }: { isOpen: boolean; onDismiss: () => void }) {
  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss} maxHeight={90}>
      <AutoColumn gap={'md'} style={{ zIndex: 10, width: '100%' }}>
        <TransactionErrorContent message="" onDismiss={onDismiss} />
      </AutoColumn>
    </Modal>
  );
}
