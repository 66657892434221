import React, { useState, useRef, useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';

import { TYPE } from '../../theme';
import { AutoColumn } from '../Column';
import { RowBetween, RowFixed } from '../Row';

import { darken } from 'polished';

enum SlippageError {
  InvalidInput = 'InvalidInput',
  RiskyLow = 'RiskyLow',
  RiskyHigh = 'RiskyHigh',
}

const FancyButton = styled.button`
  color: ${({ theme }) => theme.text1};
  align-items: center;
  height: 2rem;
  border-radius: 56px;
  font-size: 1rem;
  width: 45px;
  height: 21px;
  border: 1px solid ${({ theme }) => theme.bg3};
  outline: none;
  font-size: 12px;
  background: ${({ theme }) => theme.bg1};
`;

const Option = styled(FancyButton)<{ active: boolean }>`
  margin-right: 8px;

  background-color: ${({ active, theme }) => (active ? theme.black : theme.white)};
  color: ${({ active, theme }) => (active ? theme.white : theme.text1)};
  border: 1px solid ${({ active }) => (active ? 'black' : '#EFEFEF')};

  :hover {
    border: 1px solid ${({ active, theme }) => (active ? 'black' : theme.primary1)};
    cursor: pointer;
  }
`;

const Input = styled.input`
  background: ${({ theme }) => theme.bg1};
  font-size: 16px;
  width: 70px;
  height: auto;
  outline: none;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  color: ${({ theme, color }) => (color === 'red' ? theme.red1 : theme.text1)};
  text-align: right;
`;

const OptionCustom = styled(FancyButton)<{ active?: boolean; warning?: boolean }>`
  height: 21px;
  width: 70px;

  position: relative;
  padding: 0 0.75rem;
  flex: 1;
  border: 1px solid #efefef;
  background: transparent;
  :hover {
    border: ${({ theme, active, warning }) =>
      active && `1px solid ${warning ? darken(0.1, theme.red1) : darken(0.1, theme.primary1)}`};
  }

  input {
    width: 100%;
    height: 100%;
    border: 0px;
    border-radius: 2rem;
    font-size: 12px;
    background: transparent;
  }
`;

const SlippageEmojiContainer = styled.span`
  color: #f3841e;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;  
  `}
`;

export interface SlippageTabsProps {
  rawSlippage: number;
  setRawSlippage: (rawSlippage: number) => void;
}

export default function SlippageTabs({ rawSlippage, setRawSlippage }: SlippageTabsProps) {
  //eslint-disable-next-line
  //@ts-ignore
  const theme = useContext(ThemeContext);

  const inputRef = useRef<HTMLInputElement>();

  const [slippageInput, setSlippageInput] = useState('');

  const slippageInputIsValid =
    slippageInput === '' || (rawSlippage / 100).toFixed(2) === Number.parseFloat(slippageInput).toFixed(2);

  let slippageError: SlippageError | undefined;
  if (slippageInput !== '' && !slippageInputIsValid) {
    slippageError = SlippageError.InvalidInput;
  } else if (slippageInputIsValid && rawSlippage < 50) {
    slippageError = SlippageError.RiskyLow;
  } else if (slippageInputIsValid && rawSlippage > 500) {
    slippageError = SlippageError.RiskyHigh;
  } else {
    slippageError = undefined;
  }

  function parseCustomSlippage(value: string) {
    setSlippageInput(value);

    try {
      const valueAsIntFromRoundedFloat = Number.parseInt((Number.parseFloat(value) * 100).toString());
      if (!Number.isNaN(valueAsIntFromRoundedFloat) && valueAsIntFromRoundedFloat < 5000) {
        setRawSlippage(valueAsIntFromRoundedFloat);
      }
    } catch {}
  }

  return (
    <AutoColumn gap="md">
      <RowBetween>
        <RowFixed>
          <TYPE.black fontWeight={600} fontSize={12} color={theme.text2}>
            SLIPPAGE TOLERANCE
          </TYPE.black>
        </RowFixed>
        <RowFixed>
          <Option
            onClick={() => {
              setSlippageInput('');
              setRawSlippage(10);
            }}
            active={rawSlippage === 10}
          >
            0.1%
          </Option>
          <Option
            onClick={() => {
              setSlippageInput('');
              setRawSlippage(50);
            }}
            active={rawSlippage === 50}
          >
            0.5%
          </Option>
          <Option
            onClick={() => {
              setSlippageInput('');
              setRawSlippage(100);
            }}
            active={rawSlippage === 100}
          >
            1%
          </Option>
          <OptionCustom active={![10, 50, 100].includes(rawSlippage)} warning={!slippageInputIsValid} tabIndex={-1}>
            <RowBetween>
              {!!slippageInput &&
              (slippageError === SlippageError.RiskyLow || slippageError === SlippageError.RiskyHigh) ? (
                <SlippageEmojiContainer>
                  <span role="img" aria-label="warning">
                    ⚠️
                  </span>
                </SlippageEmojiContainer>
              ) : null}
              {/* https://github.com/DefinitelyTyped/DefinitelyTyped/issues/30451 */}
              <Input
                ref={inputRef as any}
                placeholder={(rawSlippage / 100).toFixed(2)}
                value={slippageInput}
                onBlur={() => {
                  parseCustomSlippage((rawSlippage / 100).toFixed(2));
                }}
                onChange={(e) => parseCustomSlippage(e.target.value)}
                color={!slippageInputIsValid ? 'red' : ''}
              />
              %
            </RowBetween>
          </OptionCustom>
        </RowFixed>
      </RowBetween>
      {!!slippageError && (
        <RowBetween
          style={{
            fontSize: '14px',
            color: slippageError === SlippageError.InvalidInput ? 'red' : '#F3841E',
          }}
        >
          {slippageError === SlippageError.InvalidInput
            ? 'Enter a valid slippage percentage'
            : slippageError === SlippageError.RiskyLow
            ? 'Your transaction may fail'
            : 'Your transaction may be frontrun'}
        </RowBetween>
      )}
    </AutoColumn>
  );
}
