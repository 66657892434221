import React, { useState, useMemo, useEffect, useRef } from 'react';
import { ResponsiveContainer } from 'recharts';
import { timeframeOptions } from '../../constants';

import TradingViewChart from '../TradingviewChart';
// import { RowFixed } from '../Row'
// import { OptionButton } from '../ButtonStyled'
import { getTimeframe } from '../../utils';
import { Box, Button } from 'rebass';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import { useTVLContext } from 'pages/context/TVLContext';
import { useGlobalChartData } from 'hooks/useGlobalChartData';
import './style.css';

const Panel = styled(Box)`
  position: relative;
  background-color: ${({ theme }) => theme.advancedBG};
  padding: 1.25rem;
  width: ${() => (isMobile ? '100%' : '90%')};
  max-width: ${() => (isMobile ? 'auto' : '60vw')};
  margin-top: ${() => (isMobile ? '20px !important' : '0')};
  height: 100%;
  max-height: 65vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-self: flex-end;
  border-radius: 32px;
  border: 1px solid ${({ theme }) => theme.bg3};
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.05); /* box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.01), 0px 16px 24px rgba(0, 0, 0, 0.01), 0px 24px 32px rgba(0, 0, 0, 0.01); */
`;

const CloseButton = styled(Button)`
  position: absolute;
  background: white;
  color: black !important;
  top: -40px;
  right: 0;
  width: 60px;
  font-size: 12px !important;
  padding: 0.3rem 0.6rem !important;
  border-radius: 64px !important;
  cursor: pointer;
`;

const GlobalChart = () => {
  // chart options
  // time window and window size for chart
  const timeWindow = timeframeOptions.ALL_TIME;
  const [dailyData] = useGlobalChartData();
  const { totalLiquidityUSD, liquidityChangeUSD } = useTVLContext();
  const [expanded, setExpanded] = useState(isMobile);
  const utcStartTime = getTimeframe(timeWindow);

  const chartDataFiltered = useMemo(() => {
    // let currentData = volumeWindow === VOLUME_WINDOW.DAYS ? dailyData : weeklyData
    const currentData = dailyData;
    return (
      currentData &&
      Object.keys(currentData)
        ?.map((key: any) => {
          const item = currentData[key];
          if (item.date > utcStartTime) {
            return item;
          } else {
            return true;
          }
        })
        .filter((item) => {
          return !!item;
        })
    );
  }, [dailyData, utcStartTime]);

  // update the width on a window resize
  const ref = useRef();
  const isClient = typeof window === 'object';
  //eslint-disable-next-line
  //@ts-ignore
  const [width, setWidth] = useState(ref?.current?.container?.clientWidth);

  useEffect(() => {
    if (!isClient) {
      return;
    }
    function handleResize() {
      //eslint-disable-next-line
      //@ts-ignore
      setWidth(ref?.current?.container?.clientWidth ?? width);
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isClient, width]); // Empty array ensures that effect is only run on mount and unmount

  return (
    <>
      {chartDataFiltered && totalLiquidityUSD ? (
        <Panel onClick={() => setExpanded(true)} className={expanded ? 'expanded' : 'notExpanded'}>
          <ResponsiveContainer aspect={60 / 28} ref={ref}>
            <TradingViewChart
              expanded={expanded}
              data={dailyData}
              base={totalLiquidityUSD}
              baseChange={liquidityChangeUSD}
              title="Liquidity"
              field="totalLiquidityUSD"
              width={width}
              type={'AREA'}
            />
          </ResponsiveContainer>
          <CloseButton
            onClick={(e) => {
              e.stopPropagation();
              setExpanded(false);
            }}
          >
            close
          </CloseButton>
        </Panel>
      ) : null}
    </>
  );
};

export default React.memo(GlobalChart);
