import { useState, useEffect } from 'react';
import { get2DayPercentChange, getBlockFromTimestamp, getPercentChange, useFitfiPrice } from 'pages/context/TVLContext';
import { ethers } from 'ethers';
import { updateNameData } from './usePairData';
import dayjs from 'dayjs';
import { client } from 'graph/client';
import { TOKEN_DATA } from 'graph/queries';

const getTokenData = async (address: any, fitfiPrice: any, fitfiPriceOld: any) => {
  const utcCurrentTime = dayjs();
  const utcOneDayBack = utcCurrentTime.subtract(1, 'day').startOf('minute').unix();
  const utcTwoDaysBack = utcCurrentTime.subtract(2, 'day').startOf('minute').unix();
  const oneDayBlock = await getBlockFromTimestamp(utcOneDayBack);
  const twoDayBlock = await getBlockFromTimestamp(utcTwoDaysBack);

  // initialize data arrays
  let data: any = {};
  let oneDayData: any = {};
  let twoDayData: any = {};

  try {
    // fetch all current and historical data
    const result = await client.query({
      query: TOKEN_DATA(address),
      fetchPolicy: 'cache-first',
    });

    data = result?.data?.tokens?.[0];

    // // get results from 24 hours in past
    // const oneDayResult = await client.query({
    //   query: TOKEN_DATA(address, oneDayBlock),
    // });
    // oneDayData = oneDayResult.data.tokens[0];

    // // get results from 48 hours in past
    // const twoDayResult = await client.query({
    //   query: TOKEN_DATA(address, twoDayBlock),
    // });
    // twoDayData = twoDayResult.data.tokens[0];

    // catch the case where token wasnt in top list in previous days
    if (!oneDayData) {
      const oneDayResult = await client.query({
        query: TOKEN_DATA(address, oneDayBlock),
      });
      oneDayData = oneDayResult.data.tokens[0];
    }
    if (!twoDayData) {
      const twoDayResult = await client.query({
        query: TOKEN_DATA(address, twoDayBlock),
      });
      twoDayData = twoDayResult.data.tokens[0];
    }

    // calculate percentage changes and daily changes
    const [oneDayVolumeUSD, volumeChangeUSD] = get2DayPercentChange(
      data?.tradeVolumeUSD,
      oneDayData?.tradeVolumeUSD ?? 0,
      twoDayData?.tradeVolumeUSD ?? 0
    );

    // calculate percentage changes and daily changes
    const [oneDayVolumeUT, volumeChangeUT] = get2DayPercentChange(
      data?.untrackedVolumeUSD,
      oneDayData?.untrackedVolumeUSD ?? 0,
      twoDayData?.untrackedVolumeUSD ?? 0
    );

    // calculate percentage changes and daily changes
    const [oneDayTxns, txnChange] = get2DayPercentChange(
      data?.txCount,
      oneDayData?.txCount ?? 0,
      twoDayData?.txCount ?? 0
    );

    const priceChangeUSD = getPercentChange(
      data?.derivedFITFI * fitfiPrice,
      parseFloat(oneDayData?.derivedFITFI ?? 0) * fitfiPriceOld
    );

    const currentLiquidityUSD = data?.totalLiquidity * fitfiPrice * data?.derivedFITFI;
    const oldLiquidityUSD = oneDayData?.totalLiquidity * fitfiPriceOld * oneDayData?.derivedFITFI;

    // set data
    data.priceUSD = data?.derivedFITFI * fitfiPrice;
    data.totalLiquidityUSD = currentLiquidityUSD;
    data.oneDayVolumeUSD = oneDayVolumeUSD;
    data.volumeChangeUSD = volumeChangeUSD;
    data.priceChangeUSD = priceChangeUSD;
    data.oneDayVolumeUT = oneDayVolumeUT;
    data.volumeChangeUT = volumeChangeUT;
    const liquidityChangeUSD = getPercentChange(currentLiquidityUSD ?? 0, oldLiquidityUSD ?? 0);
    data.liquidityChangeUSD = liquidityChangeUSD;
    data.oneDayTxns = oneDayTxns;
    data.txnChange = txnChange;

    // used for custom adjustments
    data.oneDayData = oneDayData?.[address];
    data.twoDayData = twoDayData?.[address];

    // new tokens
    if (!oneDayData && data) {
      data.oneDayVolumeUSD = data?.tradeVolumeUSD;
      data.oneDayVolumeETH = data.tradeVolume * data.derivedFITFI;
      data.oneDayTxns = data.txCount;
    }

    // update name data for
    updateNameData({
      token0: data,
    });
  } catch (e) {
    console.log(e);
  }
  return data;
};

export function useTokenData(tokenAddress: string): any {
  const [token, setToken] = useState<any>();
  const [fitfiPrice, fitfiPriceOld] = useFitfiPrice();

  useEffect(() => {
    if (
      token?.id !== tokenAddress &&
      fitfiPrice &&
      fitfiPriceOld &&
      tokenAddress &&
      ethers.utils.getAddress(tokenAddress.toLowerCase())
    ) {
      getTokenData(tokenAddress, fitfiPrice, fitfiPriceOld).then((data) => {
        setToken(data);
      });
    } else {
      setToken(null);
    }
  }, [fitfiPrice, fitfiPriceOld, tokenAddress]);

  return token || { priceUSD: fitfiPrice };
}
