import { ButtonPrimary } from 'components/Button';
import React from 'react';
import { AcceptRow } from './styled';

interface Props {
  onClick: () => void;
}

export const PriceAccept = ({ onClick }: Props) => {
  return (
    <AcceptRow>
      <span>Price Updated</span>
      <ButtonPrimary width={'45%'} onClick={onClick}>
        Accept
      </ButtonPrimary>
    </AcceptRow>
  );
};
