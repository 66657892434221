import { Currency, CurrencyAmount, Fraction, Percent } from 'stepex-sdk-mainnet';
import React, { useContext } from 'react';
import { Text } from 'rebass';
import { ButtonPrimary } from '../../components/Button';
import { RowBetween, RowFixed } from '../../components/Row';
import CurrencyLogo from '../../components/CurrencyLogo';
import { Field } from '../../state/mint/actions';
import { TYPE } from '../../theme';
import { createPortal } from 'react-dom';
import { PriceRow, Quote, TradeInfo } from 'components/swap/TradePrice';
import { ThemeContext } from 'styled-components';
import { numWithCommas } from 'utils/prices';
import { TOKENS_PAIRS } from 'constants/lists';
import { usePairData } from '../../hooks/usePairData';

export function ConfirmAddModalBottom({
  noLiquidity,
  price,
  currencies,
  parsedAmounts,
  poolTokenPercentage,
  onAdd,
}: {
  noLiquidity?: boolean;
  price?: Fraction;
  currencies: { [field in Field]?: Currency };
  parsedAmounts: { [field in Field]?: CurrencyAmount };
  poolTokenPercentage?: Percent;
  onAdd: () => void;
}) {
  return (
    <>
      <RowBetween>
        <TYPE.body>{currencies[Field.CURRENCY_A]?.symbol} Deposited</TYPE.body>
        <RowFixed>
          <CurrencyLogo currency={currencies[Field.CURRENCY_A]} style={{ marginRight: '8px' }} />
          <TYPE.body>{parsedAmounts[Field.CURRENCY_A]?.toSignificant(6)}</TYPE.body>
        </RowFixed>
      </RowBetween>
      <RowBetween>
        <TYPE.body>{currencies[Field.CURRENCY_B]?.symbol} Deposited</TYPE.body>
        <RowFixed>
          <CurrencyLogo currency={currencies[Field.CURRENCY_B]} style={{ marginRight: '8px' }} />
          <TYPE.body>{parsedAmounts[Field.CURRENCY_B]?.toSignificant(6)}</TYPE.body>
        </RowFixed>
      </RowBetween>
      <RowBetween>
        <TYPE.body>Rates</TYPE.body>
        <TYPE.body>
          {`1 ${currencies[Field.CURRENCY_A]?.symbol} = ${price?.toSignificant(4)} ${
            currencies[Field.CURRENCY_B]?.symbol
          }`}
        </TYPE.body>
      </RowBetween>
      <RowBetween style={{ justifyContent: 'flex-end' }}>
        <TYPE.body>
          {`1 ${currencies[Field.CURRENCY_B]?.symbol} = ${price?.invert().toSignificant(4)} ${
            currencies[Field.CURRENCY_A]?.symbol
          }`}
        </TYPE.body>
      </RowBetween>
      <RowBetween>
        <TYPE.body>Share of Pool:</TYPE.body>
        <TYPE.body>{noLiquidity ? '100' : poolTokenPercentage?.toSignificant(4)}%</TYPE.body>
      </RowBetween>
      <ButtonPrimary style={{ margin: '20px 0 0 0' }} onClick={onAdd}>
        <Text fontWeight={500} fontSize={20}>
          {noLiquidity ? 'Create Pool & Supply' : 'Add liquidity'}
        </Text>
      </ButtonPrimary>
    </>
  );
}

export default function LiquidityPrice({
  price,
  currencies,
}: {
  price?: Fraction;
  currencies: { [field in Field]?: Currency };
}) {
  const wrapper = document.getElementById('body-wrapper');
  //eslint-disable-next-line
  //@ts-ignore
  const theme = useContext(ThemeContext);

  const lpToken = TOKENS_PAIRS.filter((pair) => {
    const token0symbol =
      currencies[Field.CURRENCY_A]?.symbol === 'FITFI' ? 'WFITFI' : currencies[Field.CURRENCY_A]?.symbol;
    const token1symbol =
      currencies[Field.CURRENCY_B]?.symbol === 'FITFI' ? 'WFITFI' : currencies[Field.CURRENCY_B]?.symbol;
    return (
      (token0symbol === pair.tokens[0].symbol || token0symbol === pair.tokens[1].symbol) &&
      (token1symbol === pair.tokens[1].symbol || token1symbol === pair.tokens[0].symbol)
    );
  });
  const { reserveUSD } = usePairData(lpToken[0]?.liquidityToken.address.toLowerCase());

  return wrapper
    ? createPortal(
        <TradeInfo>
          <Text
            fontWeight={500}
            fontSize={14}
            color={theme.text2}
            style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}
          >
            {currencies[Field.CURRENCY_A] && price && reserveUSD && currencies[Field.CURRENCY_B] ? (
              <PriceRow>
                <Quote>
                  <span>POOL SIZE</span> <span>$ {numWithCommas(Number(reserveUSD).toFixed(2))}</span>
                </Quote>
                <Quote>
                  <span>{`${currencies[Field.CURRENCY_A]?.symbol} per ${currencies[Field.CURRENCY_B]?.symbol}`}</span>{' '}
                  <span>{price?.toSignificant(6)}</span>
                </Quote>
                <Quote>
                  <span>{`${currencies[Field.CURRENCY_B]?.symbol} per ${currencies[Field.CURRENCY_A]?.symbol}`}</span>{' '}
                  <span>{price?.invert()?.toSignificant(6)}</span>
                </Quote>
              </PriceRow>
            ) : null}
          </Text>
        </TradeInfo>,
        wrapper
      )
    : null;
}

export const TVL = ({ amount }: { amount: number }) => {
  const wrapper = document.getElementById('body-wrapper');
  //eslint-disable-next-line
  //@ts-ignore
  const theme = useContext(ThemeContext);

  return wrapper
    ? createPortal(
        <TradeInfo>
          <Text
            fontWeight={500}
            fontSize={14}
            color={theme.text2}
            style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}
          >
            <PriceRow>
              <Quote>
                <span>TVL</span> <span>$ {numWithCommas(amount?.toFixed(1))}</span>
              </Quote>
            </PriceRow>
          </Text>
        </TradeInfo>,
        wrapper
      )
    : null;
};
