import React, { useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useHistory, useLocation } from 'react-router-dom';
import { Text } from 'rebass';
import styled from 'styled-components';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';
import { useUserSlippageTolerance } from '../../state/user/hooks';
import TransactionSettings from '../TransactionSettings';

const StyledMenuIcon = styled('div')`
  background: white;
  font-weight: 600;
  padding: 0.3rem 0.6rem;
  border-radius: 64px;
  color: black;

  > * {
    stroke: ${({ theme }) => theme.black};
  }

  :hover {
    opacity: 0.7;
  }
`;

const StyledMenuButton = styled.button`
  position: relative;
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  padding: 0;
  height: 35px;

  border-radius: 0.5rem;

  :hover,
  :focus {
    cursor: pointer;
    outline: none;
  }

  svg {
    margin-top: 2px;
  }
`;
const StyledMenu = styled.div`
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: none;
  text-align: left;
`;

const MenuFlyout = styled.span`
  min-width: 20.125rem;
  background-color: ${({ theme }) => theme.white};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  position: absolute;
  top: 2rem;
  right: ${() => (isMobile ? '-1.5rem' : '0rem')};
  width: ${() => (isMobile ? '375px' : '420px')};
  z-index: 100;
  height: 100px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    min-width: 18.125rem;
  `};

  @media (max-width: 420px) {
    scale: 0.85;
  }
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${() => (isMobile ? '1rem 0.4rem' : '1rem')};
  height: 100%;
`;

export default function SettingsTab() {
  const node = useRef<HTMLDivElement>();
  const [open, setOpen] = useState(false);
  //eslint-disable-next-line
  //@ts-ignore
  const [userSlippageTolerance, setUserslippageTolerance] = useUserSlippageTolerance();
  const { state }: any = useLocation();
  const navigate = useHistory();
  // show confirmation view before turning on

  useOnClickOutside(
    node,
    open || state?.fromSlippage
      ? () => {
          setOpen(false);
          state?.fromSlippage && navigate.replace({ state: {} });
        }
      : undefined
  );

  return (
    <StyledMenu ref={node as any}>
      <StyledMenuButton onClick={() => setOpen(!open)} id="open-settings-dialog-button">
        <StyledMenuIcon>Settings</StyledMenuIcon>
      </StyledMenuButton>
      {(open || state?.fromSlippage) && (
        <MenuFlyout>
          <Wrapper>
            <Text fontWeight={600} fontSize={24}>
              SETTINGS
            </Text>
            <TransactionSettings rawSlippage={userSlippageTolerance} setRawSlippage={setUserslippageTolerance} />
          </Wrapper>
        </MenuFlyout>
      )}
    </StyledMenu>
  );
}
