import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { Pair } from 'stepex-sdk-mainnet';
import { Link } from 'react-router-dom';
import { SwapPoolTabs } from '../../components/NavigationTabs';
import AppBody from '../AppBody';
import FullPositionCard from '../../components/PositionCard';
import { useTokenBalancesWithLoadingIndicator } from '../../state/wallet/hooks';
import { TYPE, HideSmall } from '../../theme';
import { Text } from 'rebass';
import Card from '../../components/Card';
import { RowBetween, RowFixed } from '../../components/Row';
import { ButtonPrimary } from '../../components/Button';
import { AutoColumn } from '../../components/Column';

import { useActiveWeb3React } from '../../hooks';
import { usePairs } from '../../data/Reserves';
import { toV2LiquidityToken, useTrackedTokenPairs } from '../../state/user/hooks';
import { Dots } from '../../components/swap/styleds';
import { getPairAddresses } from 'utils';
import { useFactoryContract } from 'hooks/useContract';
import { useWalletModalToggle } from 'state/application/hooks';
import { TVL } from 'pages/AddLiquidity/ConfirmAddModalBottom';
import GlobalChart from 'components/GlobalChart';
import { isMobile } from 'react-device-detect';
import { useTVLContext } from 'pages/context/TVLContext';

const PageWrapper = styled(AutoColumn)`
  max-width: 640px;
  width: 100%;
  padding: 1rem;
  background: ${({ theme }) => theme.white};
  border-radius: 24px;
`;

const TitleRow = styled(RowBetween)`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-wrap: wrap;
    gap: 12px;
    width: 100%;
    flex-direction: column-reverse;
  `};
`;

const ButtonRow = styled(RowFixed)`
  width: 100%;
  margin-top: 16px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    flex-direction: row-reverse;
    justify-content: space-between;
  `};
`;

const ResponsiveButtonPrimary = styled(ButtonPrimary)`
  width: 100%;
  height: 40px;

  color: ${({ theme }) => theme.black};
`;

const EmptyProposals = styled.div`
  border: 1px solid ${({ theme }) => theme.text4};
  padding: 16px 12px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ListWrapper = styled.div`
  max-height: 30vh;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: lightgrey;
  }
  &::-webkit-scrollbar-thumb {
    background-color: grey;
    border-radius: 16px;
  }
`;

export default function Pool() {
  //eslint-disable-next-line
  //@ts-ignore
  const theme = useContext(ThemeContext);
  const { account } = useActiveWeb3React();
  const factory = useFactoryContract();
  const [selectedPair, setSelectedPair] = useState<Pair | null>(null);
  const [addresses, setAddresses] = useState<any[]>([]);
  const toggleWalletModal = useWalletModalToggle();

  // fetch the user's balances of all tracked V2 LP tokens
  const trackedTokenPairs = useTrackedTokenPairs();
  const tokenPairsWithLiquidityTokens = useMemo(
    () =>
      addresses.length
        ? trackedTokenPairs.map((tokens, index) => ({
            liquidityToken: toV2LiquidityToken(addresses[index][tokens[0].address], tokens[1].chainId),
            tokens,
          }))
        : [],
    [trackedTokenPairs, addresses]
  );
  const pairAddresses = useCallback(async () => {
    const data = await Promise.all(
      trackedTokenPairs.map(async ([tokenA, tokenB]) => {
        return tokenA && tokenB && !tokenA.equals(tokenB)
          ? { [tokenA.address]: await getPairAddresses(factory, tokenA, tokenB) }
          : undefined;
      })
    );
    return data;
  }, [factory, trackedTokenPairs]);

  useEffect(() => {
    account && pairAddresses().then(setAddresses);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  const liquidityTokens = useMemo(
    () => tokenPairsWithLiquidityTokens.map((tpwlt) => tpwlt.liquidityToken),
    [tokenPairsWithLiquidityTokens]
  );

  const [v2PairsBalances, fetchingV2PairBalances] = useTokenBalancesWithLoadingIndicator(
    account ?? undefined,
    liquidityTokens
  );
  // fetch the reserves for all V2 pools in which the user has a balance
  const liquidityTokensWithBalances = useMemo(
    () =>
      tokenPairsWithLiquidityTokens.filter(({ liquidityToken }) =>
        v2PairsBalances[liquidityToken?.address]?.greaterThan('0')
      ),
    [tokenPairsWithLiquidityTokens, v2PairsBalances]
  );
  const liquidityPairs = liquidityTokensWithBalances.map(({ tokens }) => tokens);
  const v2Pairs = usePairs(liquidityPairs);

  const v2IsLoading =
    fetchingV2PairBalances ||
    v2Pairs?.length < liquidityTokensWithBalances.length ||
    v2Pairs?.some((V2Pair) => !V2Pair);
  const allV2PairsWithLiquidity = v2Pairs.map(([, pair]) => pair).filter((v2Pair): v2Pair is Pair => Boolean(v2Pair));
  const { totalLiquidityUSD } = useTVLContext();

  return (
    <AppBody
      goBack={
        selectedPair
          ? () => {
              setSelectedPair(null);
            }
          : null
      }
    >
      <PageWrapper>
        <SwapPoolTabs active={'pool'} />
        {totalLiquidityUSD && !isMobile && !selectedPair ? <TVL amount={totalLiquidityUSD} /> : null}

        <AutoColumn gap="lg" justify="center">
          <AutoColumn style={{ width: '100%' }}>
            <TitleRow padding={'0'}>
              <HideSmall>
                <TYPE.mediumHeader style={{ justifySelf: 'flex-start', marginTop: 0, marginBottom: 40 }}>
                  Your liquidity
                </TYPE.mediumHeader>
              </HideSmall>
            </TitleRow>
            <ListWrapper>
              {!account ? (
                <Card padding="20px 0 0 0">
                  <TYPE.body color={theme.black} fontSize="24px" textAlign="start">
                    NO WALLET CONNECTED
                  </TYPE.body>
                </Card>
              ) : v2IsLoading ? (
                <EmptyProposals>
                  <TYPE.body color={theme.text3} textAlign="center">
                    <Dots>Loading</Dots>
                  </TYPE.body>
                </EmptyProposals>
              ) : allV2PairsWithLiquidity?.length > 0 ? (
                <>
                  {selectedPair ? (
                    <>
                      <FullPositionCard
                        setSelectedPair={setSelectedPair}
                        pair={selectedPair}
                        expanded={Boolean(selectedPair)}
                      />
                    </>
                  ) : (
                    <>
                      {allV2PairsWithLiquidity.map((v2Pair) => (
                        <FullPositionCard
                          setSelectedPair={setSelectedPair}
                          key={v2Pair.liquidityToken.address + Math.random()}
                          pair={v2Pair}
                        />
                      ))}
                    </>
                  )}
                </>
              ) : (
                <EmptyProposals>
                  <TYPE.body color={theme.text3} textAlign="center">
                    No Liquidity found
                  </TYPE.body>
                </EmptyProposals>
              )}
            </ListWrapper>
          </AutoColumn>
        </AutoColumn>
      </PageWrapper>
      {isMobile && <GlobalChart />}

      {!account ? (
        <ButtonRow>
          <ButtonPrimary onClick={toggleWalletModal}>Connect Wallet</ButtonPrimary>
        </ButtonRow>
      ) : !selectedPair ? (
        <ButtonRow>
          <ResponsiveButtonPrimary id="join-pool-button" as={Link} padding="6px 10px" to="/add/FITFI">
            <Text fontWeight={500} fontSize={16}>
              ADD LIQUIDITY
            </Text>
          </ResponsiveButtonPrimary>
        </ButtonRow>
      ) : null}
    </AppBody>
  );
}
